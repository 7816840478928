import React, { useEffect, useState } from 'react';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconPlus, IconCircles } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../../../components/accordion';
import { BaseItemVennDiagramBuilderProps, VennDiagramSegment } from '../../types';
import Segment from '@/main/containers/vennDiagram/builder/segments/segment';

function SegmentsVennDiagram(props: BaseItemVennDiagramBuilderProps & { noAccordion?: boolean }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [segments, setSegments] = useState<Partial<VennDiagramSegment>[]>(props.data.segments);

  const updateSegment = (updatedSegment: VennDiagramSegment | undefined) => {
    // console.log('saving', segmentToUpdate ? ' should update' : ' set new value', newSegment);
    if (updatedSegment) {
      props.setData({
        ...props.data,
        segments: [
          ...props.data.segments.filter(segment => segment.id !== updatedSegment?.id),
          updatedSegment
        ]
      });
    }
  };

  const deleteSegment = (segmentId: string) => {
    props.setData({
      ...props.data,
      segments: props.data.segments.filter(s => s.id !== segmentId)
    });
  };

  useEffect(() => {
    setSegments(props.data.segments);
  }, [props.data.segments]);

  return (props.noAccordion ?
    <>
      {segments.length > 0 ?
        <Stack p={4}>
          {segments.map(segment => (
            <Segment
              {...props}
              objectType={props.data.objectType}
              segment={segment}
              onSegmentChange={updateSegment}
              deleteSegment={deleteSegment}
            />
          ))}
        </Stack> :
        null
      }

      <Stack spacing={4} p={4}>
        <Button
          variant='contained'
          sx={{ width: 'fit-content' }}
          onClick={() => {
            setSegments(prevState => [
              ...prevState,
              {
                id: `segment-${props.data.segments.length + 1}-${Date.now()}`,
                label: t('segmentation.vennDiagram.builder.segments.fields.title'),
                behavior: 'union'
              }
            ]);
          }}
          startIcon={<IconPlus size='1.2rem' />}
        >
          {t('segmentation.vennDiagram.builder.segments.new')}
        </Button>
      </Stack>
    </> :
    <CustomAccordion
      title={<Stack direction='row' spacing={2} alignItems='center'>
        <Typography variant='h4'>
          {t('segmentation.vennDiagram.builder.segments.label')}
        </Typography>
        <Chip
          label={
            <Stack direction='row' spacing={2} alignItems='center'>
              <IconCircles size='1rem' />
              <Typography variant='body2'>
                {`${props.data.segments.length} ${props.data.segments.length > 1 ? t('segmentation.vennDiagram.builder.segments.nbOfMultiple') : t('segmentation.vennDiagram.builder.segments.nbOf')}`}
              </Typography>
            </Stack>
          }
          color='primary'
          size='small'
        />
      </Stack>}
      bgcolor={theme.palette.background.default}
      defaultOpen
    >
      {segments.length > 0 ?
        <Stack p={4}>
          {segments.map(segment => (
            <Segment
              {...props}
              objectType={props.data.objectType}
              segment={segment}
              onSegmentChange={updateSegment}
              deleteSegment={deleteSegment}
            />
          ))}
        </Stack> :
        null
      }

      <Stack spacing={4} p={4}>
        <Button
          variant='contained'
          sx={{ width: 'fit-content' }}
          onClick={() => {
            setSegments(prevState => [
              ...prevState,
              {
                id: `segment-${props.data.segments.length + 1}-${Date.now()}`,
                label: t('segmentation.vennDiagram.builder.segments.fields.title'),
                behavior: 'union'
              }
            ]);
          }}
          startIcon={<IconPlus size='1.2rem' />}
        >
          {t('segmentation.vennDiagram.builder.segments.new')}
        </Button>
      </Stack>
    </CustomAccordion>
  );
}

export default SegmentsVennDiagram;
