import { Icon, IconCirclesRelation, IconProps, IconUsers } from '@tabler/icons-react';
import React from 'react';
import {
  SegmentationPossibleFilterOnEntityTypes,
  SegmentationPossibleOnEntityTypes,
  SegmentationPossibleOutputEntityTypes,
  SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import { uniq } from 'lodash';
import IconBuildings from '@/assets/custom/IconBuildings';
import { CustomSegmentationData } from '../types';

interface Workflow
{
  output: SegmentationPossibleFilterOnEntityTypes,
  workflowId: SegmentationWorkflowIds,
  labelId: string,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>
}

interface EntryPoint {
  entryPoint: SegmentationPossibleOutputEntityTypes,
  labelId: string,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  possibleFilterTypes: SegmentationPossibleOnEntityTypes[],
  possibleCriteriaTypes: SegmentationPossibleOnEntityTypes[],
  possibleCriteriaOnTypes: SegmentationPossibleFilterOnEntityTypes[],
  correlationTable: {[key in SegmentationPossibleOnEntityTypes]?: SegmentationPossibleFilterOnEntityTypes },
  workflows: Workflow[]
}

interface SegmentationPossibleWorkflow {
  dataSetId: 'deecPersons' | 'deecCompanies',
  labelId: string,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>,
  entryPoints: EntryPoint[]
}

export const segmentationPossibleWorkflows: SegmentationPossibleWorkflow[] = [
  {
    dataSetId: 'deecPersons',
    labelId: 'entities.persons.label',
    icon: IconUsers,
    entryPoints: [
      {
        entryPoint: 'person1',
        labelId: 'entities.persons.label',
        icon: IconUsers,
        possibleFilterTypes: ['person', 'company', 'person2person'],
        possibleCriteriaTypes: ['person', 'company'],
        possibleCriteriaOnTypes: ['person1', 'company1'],
        correlationTable: {
          person: 'person1',
          company: 'company1'
        },
        workflows: [
          {
            output: 'person1',
            workflowId: 'person1_link_person2',
            labelId: 'entities.persons.label',
            icon: IconUsers
          },
          {
            output: 'company1',
            workflowId: 'person1-companies_person2-companies',
            labelId: 'entities.persons.label',
            icon: IconUsers
          }
        ]
      },
      {
        entryPoint: 'person2person',
        labelId: 'entities.relations.multiple',
        icon: IconCirclesRelation,
        possibleFilterTypes: ['person', 'company', 'person2person'],
        possibleCriteriaTypes: ['person2person'],
        possibleCriteriaOnTypes: ['person1', 'company1', 'person2person'],
        correlationTable: {
          person2person: 'person2person'
        },
        workflows: [
          {
            output: 'person2person',
            workflowId: 'person1_link_person2',
            labelId: 'entities.relations.multiple',
            icon: IconCirclesRelation
          }
        ]
      },
      {
        entryPoint: 'person2',
        labelId: 'entities.persons.label',
        icon: IconUsers,
        possibleFilterTypes: ['person', 'company', 'person2person'],
        possibleCriteriaTypes: ['person', 'company'],
        possibleCriteriaOnTypes: ['person1', 'company1', 'person2person', 'person2', 'company2'],
        correlationTable: {
          person: 'person2',
          person2person: 'person2person',
          company: 'company2'
        },
        workflows: [
          {
            output: 'person2',
            workflowId: 'person1_link_person2',
            labelId: 'entities.persons.label',
            icon: IconUsers
          },
          {
            output: 'company2',
            workflowId: 'person1-companies_person2-companies',
            labelId: 'entities.persons.label',
            icon: IconUsers
          }
        ]
      }
    ]
  },
  {
    dataSetId: 'deecCompanies',
    labelId: 'entities.companies.label',
    icon: IconBuildings,
    entryPoints: [
      {
        entryPoint: 'company',
        labelId: 'entities.companies.label',
        icon: IconBuildings,
        possibleFilterTypes: ['company'],
        possibleCriteriaTypes: ['company'],
        possibleCriteriaOnTypes: ['company'],
        correlationTable: {
          company: 'company'
        },
        workflows: [
          {
            output: 'company',
            workflowId: 'simple_company',
            labelId: 'entities.companies.label',
            icon: IconBuildings
          }
        ]
      }
    ]
  }
];

export const possibleDataSetIds = (tmpPossibleOutputEntities?: CustomSegmentationData['possibleOutputEntities']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleOutputEntities ? possibleWorkflow.entryPoints.find(entryPoint => tmpPossibleOutputEntities.includes(entryPoint.entryPoint)) : possibleWorkflow)).map(possibleWorkflow => possibleWorkflow.dataSetId));
export const possibleOutputEntities = (tmpPossibleDataSetIds?: CustomSegmentationData['possibleDataSetIds']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleDataSetIds ? tmpPossibleDataSetIds.includes(possibleWorkflow.dataSetId) : true)).map(possibleWorkflow => possibleWorkflow.entryPoints.map(entryPoint => entryPoint.entryPoint)).flat(1));
export const possibleWorkflowIds = (tmpPossibleDataSetIds?: CustomSegmentationData['possibleDataSetIds'], tmpPossibleOutputEntities?: CustomSegmentationData['possibleOutputEntities']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleDataSetIds ? tmpPossibleDataSetIds.includes(possibleWorkflow.dataSetId) : true)).map(possibleWorkflow => possibleWorkflow.entryPoints.filter(entryPoint => (tmpPossibleOutputEntities ? tmpPossibleOutputEntities.includes(entryPoint.entryPoint) : true)).map(entryPoint => entryPoint.workflows.map(workflow => workflow.workflowId))).flat(2));
export const possibleFilterTypes = (tmpPossibleDataSetIds?: CustomSegmentationData['possibleDataSetIds'], tmpPossibleOutputEntities?: CustomSegmentationData['possibleOutputEntities']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleDataSetIds ? tmpPossibleDataSetIds.includes(possibleWorkflow.dataSetId) : true)).map(possibleWorkflow => possibleWorkflow.entryPoints.filter(entryPoint => (tmpPossibleOutputEntities ? tmpPossibleOutputEntities.includes(entryPoint.entryPoint) : true)).map(entryPoint => entryPoint.possibleFilterTypes)).flat(2));
export const possibleCriteriaTypes = (tmpPossibleDataSetIds?: CustomSegmentationData['possibleDataSetIds'], tmpPossibleOutputEntities?: CustomSegmentationData['possibleOutputEntities']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleDataSetIds ? tmpPossibleDataSetIds.includes(possibleWorkflow.dataSetId) : true)).map(possibleWorkflow => possibleWorkflow.entryPoints.filter(entryPoint => (tmpPossibleOutputEntities ? tmpPossibleOutputEntities.includes(entryPoint.entryPoint) : true)).map(entryPoint => entryPoint.possibleCriteriaTypes)).flat(2));
export const possibleCriteriaOnTypes = (tmpPossibleDataSetIds?: CustomSegmentationData['possibleDataSetIds'], tmpPossibleOutputEntities?: CustomSegmentationData['possibleOutputEntities']) => uniq(segmentationPossibleWorkflows.filter(possibleWorkflow => (tmpPossibleDataSetIds ? tmpPossibleDataSetIds.includes(possibleWorkflow.dataSetId) : true)).map(possibleWorkflow => possibleWorkflow.entryPoints.filter(entryPoint => (tmpPossibleOutputEntities ? tmpPossibleOutputEntities.includes(entryPoint.entryPoint) : true)).map(entryPoint => entryPoint.possibleCriteriaOnTypes)).flat(2));
