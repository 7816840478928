import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Controls, applyEdgeChanges, ControlButton, EdgeTypes, EdgeChange, useReactFlow } from 'reactflow';
import { CompanyEntity, EntityLinksAggregation, IDataElement, PersonEntity } from '@deecision/dna-interfaces';
import 'reactflow/dist/style.css';
import { IconFileDownload, IconTableExport, IconViewportTall } from '@tabler/icons-react';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import { Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { MainEntity } from '@/main/containers/network/customNodes/customNode';
import { RelationsChartProps } from '../circles/chart';
import { CustomEdge } from '@/components/charts/utils/customEdge';
import { PersonCard } from '@/main/containers/network/customNodes/personCard';
import { CompaniesCard } from '@/main/containers/network/customNodes/companiesCard';
import { WarningShaParentNode } from '@/main/containers/network/customNodes/warningShaNode';
import { DataElementsQueryService, EntityLinksQueryAggregatorService } from '@/api/services/query';
import { cardHeight, DataElementChipType, EdgeType, labelEdgeColor, NodeType } from '../types';
import SimpleDisplayFilters, { SimpleFilter } from '@/components/filters/simple';
import { SortNetworkArrayByCriteria } from '@/main/containers/network/customNodes/miscellaneous/sortNetworkArrayByCriteria';
import { CompanyGetter } from '@/main/providers/getter';
import { getHighestPercentageShare, getTotalPercentageShare } from '@/main/containers/network/utils';
import { downloadRelationChartOnClick } from '@/utils/exportData/downloadAsPng';
import SimpleDisplaySwitch, { SettingsProps } from '@/components/filters/simple/switch';
import SimpleDisplaySort, { SimpleSort } from '@/components/sort';
import FullscreenButton from '@/components/charts/utils/fullscreenButton';
import { ElementBaseStyle, ElementOnFullscreenStyle, filterBaseStyle, filterOnFullscreenStyle, useFullscreen } from '@/wrappers/fullscreen';
import BaseDnaEntitiesService from '@/main/modules/entities/services/entities';
import UnloadableDueToEntityNumberComponent from './simple/unloadableDisplay';

const baseFilterInit = [
  {
    label: 'Parent',
    id: 'parent',
    active: false,
    family: 'Show Node'
  },
  {
    label: 'Children',
    id: 'children',
    active: false,
    family: 'Show Node'
  },
  {
    label: 'Advisors',
    id: 'advisors',
    active: false,
    family: 'Show Node'
  },
  {
    label: 'Shareholders',
    id: 'shareholders',
    active: false,
    family: 'Show Node'
  },
  {
    label: 'Executive',
    id: 'executive',
    active: false,
    family: 'Show Node'
  }
];

export const formattedResults = (data: EntityLinksAggregation[], uniqueEntity1Ids: string[]) => uniqueEntity1Ids.map((uniqueEntity1Id) => {
  const groupedResults = data.map(item => (item.entity1.entityId === uniqueEntity1Id ? item : false)).filter(Boolean);

  return {
    entityId: uniqueEntity1Id,
    results: groupedResults
  };
});

function RelationsTree(props: Omit<RelationsChartProps, 'filters'> & { setDataElementsChips: React.Dispatch<React.SetStateAction<DataElementChipType | undefined>>, nbRelations: number }) {
  const { t } = useTranslation();
  const entitieBasePoint = { x: 600, y: 300 };
  const startId = 2;
  const mainEntityNode: NodeType = { id: '1', type: 'mainEntitie', position: entitieBasePoint, data: { label: props.entity.name, entityType: props.entity.entityType, cardData: undefined, cardLinks: undefined } };
  const { fitView } = useReactFlow();
  const { isFullscreen, setIsFullscreen } = useFullscreen();
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [onSortClickState, setOnSortClickState] = useState(false);
  const [onCsvDownload, setOnCsvDownload] = useState(false);
  const [isDataReadyToDownload, setIsDataReadyToDownload] = useState(false);
  const [mainEntity, setMainEntity] = useState(mainEntityNode);
  const [nodes, setNodes] = useState<NodeType[]>([mainEntity]);
  const [edges, setEdges] = useState<{ id: string, source: string, target: string }[]>([]);
  const [linksToPersons, setLinksToPersons] = useState<EntityLinksAggregation[]>([]);
  const [linksToCompanies, setLinksToCompanies] = useState<EntityLinksAggregation[]>([]);
  const [dataElements, setDataElements] = useState<IDataElement[]>();
  const [personsData, setPersonsData] = useState<PersonEntity[]>([]);
  const [companiesData, setCompaniesData] = useState<CompanyEntity[]>([]);
  const [personsLinks, setPersonsLinks] = useState<any[]>([]);
  const [companiesLinks, setCompaniesLinks] = useState<any[]>([]);

  const [entity, setEntity] = useState<(EntityLinksAggregation | undefined)[]>([]);
  const [isPersonInitialized, setIsPersonInitialized] = useState(false);
  const [filters, setFilters] = useState<SimpleFilter[]>(baseFilterInit);
  const [sortList, setSortList] = useState<SimpleSort[]>([
    {
      id: 'alphabet',
      label: 'Alphabet',
      direction: 'asc'
    },
    {
      id: 'proxemee',
      label: 'Proxemee',
      direction: undefined
    },
    {
      id: 'type',
      label: 'Type',
      direction: undefined
    },
    {
      id: 'shares',
      label: t('filters.shares'),
      direction: undefined
    }
  ]);
  const [settingsList, setSettingsList] = useState<SettingsProps[]>([
    {
      label: 'Proxemee',
      id: 'prxmee',
      status: true
    },
    {
      label: 'Nationalities',
      id: 'nationalities',
      status: true

    },
    {
      label: 'Residence country',
      id: 'residence',
      status: true

    },
    {
      label: 'Listed Company',
      id: 'listedCompany',
      status: true

    },
    {
      label: 'Edge Label',
      id: 'label',
      status: true
    }
  ]);
  const filtersRef = useRef(filters);
  const sortListRef = useRef(sortList);
  const settingsListRef = useRef(settingsList);
  const flowRef = useRef<HTMLDivElement>(null);
  const nodesRef = useRef(nodes);
  const linksServiceToCompany = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: props.entity.entityType,
    toEntityType: 'deecCompany'
  });
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: props.entity.entityType,
    toEntityType: 'deecPerson'
  });
  const dataElementsService = new DataElementsQueryService({ entityType: props.entity.entityType });
  const nodeTypes = {
    mainEntitie: MainEntity,
    personEntitie: PersonCard,
    companieEntitie: CompaniesCard,
    warningShaParent: WarningShaParentNode
  };
  const edgeTypes: EdgeTypes = {
    custom: CustomEdge
  };
  const memoizedNodeTypes = useMemo(() => nodeTypes, []);
  const memoizedEdgeTypes = useMemo(() => edgeTypes, []);
  const companyGetter = useMemo(() => entity.map(node => (
    node ?
      new CompanyGetter({
        data: node as unknown as CompanyEntity,
        errorMsg: t('common.utils.unknown')
      }):
      undefined
  )
  ), [entity]);

  const triggerDataCalculationForCSV = () => {
    setOnCsvDownload(true);
  };

  const handleKeyDown = (event: { ctrlKey: any }) => {
    if (event.ctrlKey) {
      setIsCtrlPressed(true);
    }
  };

  const handleKeyUp = (event: { ctrlKey: boolean, keyCode: number }) => {
    if (event.ctrlKey) {
      setIsCtrlPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleWheel = (event: { preventDefault: () => void }) => {
    if (!isCtrlPressed) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isPersonInitialized){
      setEdges([]);
      setMainEntity(mainEntityNode);
      setNodes([]);
      setEdges([]);
      setLinksToPersons([]);
      setLinksToCompanies([]);
      setDataElements([]);
      setPersonsData([]);
      setCompaniesData([]);
      setPersonsLinks([]);
      setCompaniesLinks([]);
      setEntity([]);
      setIsPersonInitialized(false);

      setOnSortClickState(!onSortClickState);
    }
  }, [
    sortList,
    // Doesnt work if used as a single condition retrieving a change in the family active
    filters.some(
      (element: SimpleFilter) => element.id === 'sortAlphabet' && element.active
    ),
    filters.some(
      (element: SimpleFilter) => element.id === 'sortProxemee' && element.active
    ),
    filters.some(
      (element: SimpleFilter) => element.id === 'sortType' && element.active
    ),
    filters.some(
      (element: SimpleFilter) => element.id === 'sortShares' && element.active
    )
    /// ///////////////////////////////////////////////////////////////////////////////////
  ]);

  useEffect(() => {
    if (props.nbRelations > 200 && !onCsvDownload) {
      return;
    }
    const sortObj = sortList.find(sort => sort.direction);
    const sortingType = sortList.find(sort => sort.direction)?.label;

    linksServiceToPerson.get(props.id)
      .then((res) => {
        if (res.data) {
          const sortedData = SortNetworkArrayByCriteria(res.data, sortingType || 'Alphabet', sortObj?.direction === 'asc');
          const entityIdList = sortedData.map((item: any) => item.entity2?.entityId);
          const entityService = new BaseDnaEntitiesService<PersonEntity>({ entityType: 'deecPerson' });

          if (props.nbRelations < 200) {
            linksServiceToPerson.getAllQuerys(_.isEmpty(entityIdList) ? ['notdefined'] : entityIdList, undefined, ['deecPerson']).then((response) => {
              if (response.data) {
                setPersonsLinks(formattedResults(response.data, entityIdList));
              }
            }).catch(e => console.log(e));
          }

          setLinksToPersons(sortedData);
          entityService.getByIds(entityIdList, 'deecPerson').then((response: any) => {
            if (response.data) {
              const validEntities = response.data?.filter((ent: null) => ent !== null);
              setEntity(previousState => [...previousState, ...response.data]);
              setPersonsData(validEntities as PersonEntity[]);
            }
          }).catch((e) => {
            console.log(e);
          });
          setIsPersonInitialized(true);
        }
      })
      .catch(() => setLinksToPersons([]));
    linksServiceToCompany.get(props.id)
      .then((res) => {
        if (res.data) {
          const sortedData = SortNetworkArrayByCriteria(res.data, sortingType || 'Alphabet', sortObj?.direction === 'asc');
          const entityIdList = sortedData.map((item: any) => item.entity2?.entityId);
          const entityService = new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'deecCompany' });

          if (props.nbRelations < 200) {
            linksServiceToCompany.getAllQuerys(_.isEmpty(entityIdList) ? ['notdefined'] : entityIdList, undefined, ['deecCompany']).then((response) => {
              if (response.data) {
                setCompaniesLinks(formattedResults(response.data, entityIdList));
              }
            }).catch(e => console.log(e));
          }
          setLinksToCompanies(sortedData);

          entityService.getByIds(entityIdList, 'deecCompany').then((response: any) => {
            if (response.data) {
              const validEntities = response.data?.filter((entityFromResponse: null) => entityFromResponse !== null);
              setEntity(previousState => [...previousState, ...response.data]);
              setCompaniesData(validEntities as CompanyEntity[]);
            }
          }).catch((e) => {
            console.log(e);
          });
        }
      })
      .catch(() => setLinksToCompanies([]));

    // we update the main entity and reset the edges
    const newMainEntity: NodeType = { id: '1', type: 'mainEntitie', position: entitieBasePoint, data: { label: props.entity.name || '', entityType: props.entity.entityType, entityId: props.entity.entityId, cardData: undefined, cardLinks: undefined } };
    setEdges([]);
    setMainEntity(newMainEntity);
    setNodes([newMainEntity]);
    setIsDataReadyToDownload(true);
  }, [
    props.id,
    onSortClickState,
    onCsvDownload
    // sortList,
    // // Doesnt work if used as a single condition retrieving a change in the family active
    // filters.some(
    //   (element: SimpleFilter) => element.id === 'sortAlphabet' && element.active
    // ),
    // filters.some(
    //   (element: SimpleFilter) => element.id === 'sortProxemee' && element.active
    // ),
    // filters.some(
    //   (element: SimpleFilter) => element.id === 'sortType' && element.active
    // ),
    // filters.some(
    //   (element: SimpleFilter) => element.id === 'sortShares' && element.active
    // )
    /// ///////////////////////////////////////////////////////////////////////////////////
  ]);

  useEffect(() => {
    dataElementsService.findByQuery({ entityId: props.entity.entityId }).then((response: ApiArrayResponse<IDataElement>) => {
      setDataElements(response.data);
    }).catch((error) => {
      console.error('error', error);
    });
  }, []);

  useEffect(() => {
    const parentCompaniesDataElm = dataElements?.find(element => element.type.includes('parentCompanies'));
    const childrenCompaniesDataElm =  dataElements?.find(element =>element.type.includes('childrenCompanies'));

    props.setDataElementsChips({
      parent: {
        dataInfo: parentCompaniesDataElm?.dataInfo.status,
        scheduling: parentCompaniesDataElm?.scheduling.status
      },
      children: {
        dataInfo: childrenCompaniesDataElm?.dataInfo.status,
        scheduling: childrenCompaniesDataElm?.scheduling.status
      }
    });
  }, [dataElements]);

  useEffect(() => {
    const PersonDataNotInitializedEarlyReturn = personsData.length < 1 || (personsLinks.length < 1 && personsData.length + companiesData.length < 200) || linksToPersons.length < 1;
    const NetworkTooWideEarlyReturn = personsData.length + companiesData.length > 200 && !onCsvDownload;

    if (PersonDataNotInitializedEarlyReturn || NetworkTooWideEarlyReturn) {
      return;
    }

    const offsetYMiddletoTop = Math.floor(linksToPersons.length / 2);
    const startingYpointAsCompanyMainEntity = entitieBasePoint.y - (cardHeight * offsetYMiddletoTop);
    let shareholderTotalPercentage = 0;
    const newNodes = (linksToPersons ?? []).map((persons, index) => {
      const commonDataProperty = {
        label: persons.entity2.name || '' as string,
        entitie: persons,
        entitieBasePoint,
        source: 'Right',
        target: 'Left',
        category: 'person',
        filters: filtersRef,
        sortList: sortListRef,
        settingsList: settingsListRef,
        filtersId: [
          persons.links[0].type === 'has.shareholder' || persons.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null,
          persons.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null,
          'parent'
        ].filter(Boolean),
        deploy: false,
        nodes: nodesRef,
        setNodes,
        setEdges,
        prxmee: persons.links[0]?.details?.prxmee?.score || 0,
        nodeNameFrom: persons.entity1.name,
        sharesPercentage: getHighestPercentageShare(persons),
        cardData: personsData.find(person => person.entityId === persons.entity2?.entityId),
        cardLinks: personsLinks.find(link => link.entityId === persons.entity2?.entityId)?.results
      };
      const person1ActiveTypes = persons.links[0].details?.companies ? persons.links[0].details?.companies[0]?.person1?.activePositionTypes : [];
      const person2ActiveTypes = persons.links[0].details?.companies ? persons.links[0].details?.companies[0]?.person2?.activePositionTypes : [];

      const commonPositionTypes = person1ActiveTypes.filter(type => person2ActiveTypes.includes(type));
      const labelBetweenPerson = commonPositionTypes.map(type => `Co-${type}`);

      shareholderTotalPercentage += persons.links[0]?.details?.sharesPercentage || 0;
      if (props.entity.entityType.includes('Person')) {
        const startingYpointAsPersonMainEntity = entitieBasePoint.y - (cardHeight * linksToPersons.length);

        // related entity when main entity is a person
        return {
          id: String(index + startId),
          type: 'personEntitie',
          position: { x: entitieBasePoint.x + 520, y: startingYpointAsPersonMainEntity - cardHeight + (index * cardHeight) },
          hidden: filters.find(filter => (filter.id === (persons.links[0].type === 'has.shareholder' ? 'shareholders' : 'parent')))?.active,
          data: {
            ...commonDataProperty,
            isAdvisor: true,
            advisorType: 'representative',
            labelBetweenPerson
          }
        };
      }

      // when main entity is a company
      return ({
        id: String(index + startId),
        type: 'personEntitie',
        position: { x: -300, y: startingYpointAsCompanyMainEntity + (index * cardHeight) },
        hidden: filters.find(filter => (filter.id === (persons.links[0].type === 'has.shareholder' ? 'shareholders' : 'parent')))?.active,
        data: {
          ...commonDataProperty
        }
      });
    });

    setNodes((previousState) => {
      // we clean all compayEntities node to replace them with the new ones person entities nodes will be handled in the other specific effect
      const nodesToKeep = previousState.filter(node => node.type === 'companieEntitie' || node.type === 'mainEntitie');

      return [...nodesToKeep, ...newNodes as any[]];
    });

    setEdges((previousState) => {
      const edgeToKeep = previousState.filter((edge: EdgeType) => edge.linkTo === 'person' || edge.linkTo === 'companie');

      const newPersonEdges = ((linksToPersons ?? []).map((person, index) => {
        const isParent = person.links[0].type === 'has.representative' || person.links[0].type === 'has.shareholder';
        const actualSharePercentage = getHighestPercentageShare(person);

        const person1ActiveTypes = person.links[0].details?.companies ? person.links[0].details?.companies[0]?.person1?.activePositionTypes : [];
        const person2ActiveTypes = person.links[0].details?.companies ? person.links[0].details?.companies[0]?.person2?.activePositionTypes : [];

        const commonPositionTypes = person1ActiveTypes.filter(type => person2ActiveTypes.includes(type));
        const labelBetweenPerson = commonPositionTypes.map(type => `Co-${type}`);
        const allPositionTypes = [...new Set(
          person.links
            .flatMap(link => link.details?.positionTypes || [])
            .filter(positionType => positionType)
        )];

        return ({
          id: `e1-${index + startId + 1}`,
          type: 'custom',
          source: isParent ? String(index + startId) : '1',
          target: isParent ? '1' : String(index + startId),
          label: allPositionTypes || labelBetweenPerson || [],
          linkTo: 'companie',
          hidden: filters.find(filter => (filter.id === (person.links[0].type === 'has.shareholder' ? 'shareholders' : isParent ? 'parent' : 'children')))?.active,
          data: {
            mainEntitie: props.entity.entityType.includes('Person') ? 'person' : 'companie',
            entitie: props.entity,
            sourceType: props.entity.entityType.includes('Person') ? 'person' : 'companie',
            targetType: person.entity2.entityType.includes('Person') ? 'person' : 'companie',
            arrowAt: isParent ? 'end' : 'start',
            sharesPercentage: actualSharePercentage,
            sharesPercentageWarningLabel: !(actualSharePercentage || (actualSharePercentage && actualSharePercentage < 0.5 && shareholderTotalPercentage > 99.5)),
            filtersId: [
              person.links[0].type === 'has.shareholder' || allPositionTypes?.includes('sha') ? 'shareholders' : null,
              allPositionTypes?.includes('exe') ? 'executive' : null,
              'parent'
            ].filter(Boolean),
            labelEdgeColor:
              allPositionTypes?.includes('sha') && allPositionTypes?.includes('exe') ?
                labelEdgeColor.both :
                allPositionTypes?.includes('sha') ?
                  labelEdgeColor.sha : allPositionTypes?.includes('exe') ?
                    labelEdgeColor.exe : undefined,
            filters: filtersRef,
            sortList: sortListRef,
            settingsList: settingsListRef
          }
        });
      }));

      return ([...edgeToKeep, ...newPersonEdges]);
    });
  }, [linksToPersons, personsData, personsLinks]);

  useEffect(() => {
    if ((!isPersonInitialized || companiesData.length < 1 || companiesLinks.length < 1)
      || (personsData.length + companiesData.length > 200 && !onCsvDownload)
    ) { // CONDITION HERE TO FIND TO UPDATE LINKS
      return;
    }
    const companyAdvisorXoffset = 600;
    const companyRepresentedXoffset = 800;
    const count = linksToCompanies?.reduce((acc, companie) => {
      const hasAdv = companie?.links[0]?.details?.positionTypes?.some((positionType: string) => positionType.includes('adv'));

      return acc + (hasAdv ? 0 : 1);
    }, 0);
    const startingYpointForRepresentedCompanies = entitieBasePoint.y - (cardHeight * Math.floor(count / 2));
    const startingYpointForRepresentativeCompanies = entitieBasePoint.y + (cardHeight * Math.floor(linksToPersons.length / 2));
    const advisorsParsed = linksToCompanies?.filter((companie: any) => {
      const positionTypes = companie.links[0]?.details?.positionTypes || [];
      const rolePositionTypes = companie.links[0]?.details?.role?.positionTypes || []; // Check ADV in role maybe

      return positionTypes.some((positionType: string) => positionType.includes('adv')) ||
        rolePositionTypes.some((positionType: string) => positionType.includes('adv'));
    });
    const startingAdvRepresentativeYpoint = entitieBasePoint.y - 200 - (cardHeight * Math.floor(advisorsParsed.length / 2));
    let indexAdvisorParentCompany = -1;
    let indexAdvisorChildrenCompany = -1;
    let indexChildrenCompany = -1;
    let indexParentCompany = -1;
    const shareholderTotalPercentage = getTotalPercentageShare(linksToPersons, linksToCompanies);

    const newNodes = (linksToCompanies ?? []).map((companie: any, index) => {
      const defaultProperty = {
        id: String(index + startId + (linksToPersons.length ?? 0)),
        type: 'companieEntitie'
      };
      const defaultData = {
        label: companie.entity2.name || '' as string,
        entitie: companie,
        filters: filtersRef,
        sortList: sortListRef,
        settingsList: settingsListRef,
        setNodes,
        setEdges,
        nodes: nodesRef,
        category: 'company',
        entitieBasePoint,
        deploy: false,
        isAdvisor: false,
        source: 'Right',
        target: 'Left',
        nodeNameFrom: companie.entity1.name,
        isListedCompany: companiesData.find(company => company.name === companie.entity2?.name)?.tags?.some(elem => elem.value === 'listedCompany'),
        cardData: companiesData.find(company => company.entityId === companie.entity2?.entityId),
        cardLinks: companiesLinks.find(link => link.entityId === companie.entity2?.entityId)?.results
      };

      if (companie.links[0]?.details?.positionTypes?.includes('adv') || companie.links[0].details?.role?.positionTypes?.includes('adv')) {
        // Checking advisors but it positionTypes should be in the root of details and not in role
        // advisors
        if (companie.links[0]?.type === 'has.representative') {
          indexAdvisorParentCompany += 1;

          // parent advisors
          return {
            ...defaultProperty,
            position: { x: entitieBasePoint.x + companyAdvisorXoffset, y: startingAdvRepresentativeYpoint + (indexAdvisorParentCompany * cardHeight) },
            hidden: filters.find(filter => filter.id === 'advisors')?.active,
            data: {
              ...defaultData,
              isAdvisor: true,
              advisorType: 'representative',
              filtersId: ['parent', 'advisors'],
              nodeFromType: 'parent advisors'
            }
          };
        }
        // is advisor
        indexAdvisorChildrenCompany += 1;

        return {
          ...defaultProperty,
          position: { x: entitieBasePoint.x + companyAdvisorXoffset, y: 600 + (indexAdvisorChildrenCompany * cardHeight), advisorType: 'represented' },
          hidden: filters.find(filter => filter.id === 'advisors')?.active,
          data: {
            ...defaultData,
            isAdvisor: true,
            advisorType: 'represented',
            filtersId: ['advisors'],
            nodeFromType: 'advisors'
          }
        };

      }
      // representative / represented companies

      if (companie.links[0]?.type === 'has.representative') {
        indexParentCompany += 1;

        // entity2 is a parent company
        return {
          ...defaultProperty,
          position: { x: -300, y: startingYpointForRepresentativeCompanies + (indexParentCompany * cardHeight) + cardHeight }, // Check for a fix here
          hidden: filters.find(filter => filter.id === 'parent')?.active,
          data: {
            ...defaultData,
            filtersId: [
              companie.links[0].details?.positionTypes?.includes('sha') ? 'shareholders' : null,
              companie.links[0].details?.positionTypes?.includes('exe') ? 'executive' : null,
              'parent'
            ].filter(Boolean),
            nodeFromType: 'parent'
          }
        };
      }
      // entity2 is a children company
      indexChildrenCompany += 1;

      return {
        ...defaultProperty,
        position: { x: 1300 + companyRepresentedXoffset, y: startingYpointForRepresentedCompanies + (indexChildrenCompany * cardHeight) },
        hidden: filters.find(filter => filter.id === 'children')?.active,
        data: {
          ...defaultData,
          filtersId: ['children'],
          nodeFromType: 'children'
        }
      };
    });

    setNodes((previousState) => {
      // we clean all compayEntities node to replace them with the new ones person entities nodes will be handled in the other specific effect
      const nodesToKeep = previousState.filter((node: NodeType) => node.type === 'personEntitie' || node.type === 'mainEntitie');
      let actualSharePercentage = -1;

      linksToPersons.forEach((person) => {
        if (( person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha'))) {
          actualSharePercentage = getHighestPercentageShare(person);
        }
      });
      const warningShaNode = ((actualSharePercentage === -1 || actualSharePercentage > 0) && shareholderTotalPercentage < 99.5 && props.entity.entityType.includes('Company')) ?
        {
          id: String(linksToPersons.length + linksToCompanies.length + 2),
          type: 'warningShaParent',
          position: {
            x: -300,
            y: startingYpointForRepresentativeCompanies + (((indexParentCompany + 1) * cardHeight)) + cardHeight
          },
          hidden: false,
          data: {
            source: 'Right',
            target: 'Left',
            label: '',
            shareholderTotalPercentage: shareholderTotalPercentage < 0 ? 0 : shareholderTotalPercentage
          },
          category: 'companie'
        }
        : null;

      return warningShaNode ? [...nodesToKeep, ...newNodes as any[], warningShaNode] : [...nodesToKeep, ...newNodes as any[]];
    });

    setEdges((previousState) => {
      const defaultEdgeProperty = {
        type: 'custom',
        linkTo: 'companie',
        style: {
          strokeWidth: 3
        }
      };
      const defaultDataProperty = {
        mainEntitie: props.entity.entityType.includes('Person') ? 'person' : 'companie',
        entitie: props.entity,
        sourceType: props.entity.entityType.includes('Person') ? 'person' : 'companie',
        targetType: 'companie',
        arrowAt: 'end',
        filters: filtersRef,
        settingsList: settingsListRef
      };
      const edgeToKeep = previousState.filter((edge: EdgeType) => edge.linkTo === 'person' || edge.linkTo === 'companie');

      const newCompaniesEdge = (linksToCompanies ?? []).map((companie: any, index) => {
        const allPositionTypes = [...new Set(
          companie.links
            .flatMap((link: { details: { positionTypes: string[] } }) => link.details?.positionTypes || [])
            .filter((positionType: string[]) => positionType)
        )];

        const linkedCompanieId = index + startId + (linksToPersons.length ?? 0);
        const labelColor =  allPositionTypes?.includes('sha') && allPositionTypes?.includes('exe') ?
          labelEdgeColor.both :
          allPositionTypes?.includes('sha') ?
            labelEdgeColor.sha : allPositionTypes?.includes('exe') ?
              labelEdgeColor.exe : undefined;
        const defaultMainEdgeProperty = {
          id: `e1-${linkedCompanieId}`,
          source: '1',
          target: String(linkedCompanieId),
          label: allPositionTypes || []
        };

        if (allPositionTypes?.includes('adv') || companie.links[0].details?.role?.positionTypes?.includes('adv')) {
        // Checking advisors but it positionTypes should be in the root of details and not in role
          // advisors
          if (companie.links[0]?.type === 'has.representative') {
            // parent advisors
            return {
              ...defaultMainEdgeProperty,
              sourceHandle: 'top',
              label: ['adv'],
              hidden: filters.find(filter => (filter.id === 'advisors'))?.active,
              data: {
                ...defaultDataProperty,
                arrowAt: 'start',
                labelEdgeColor: labelColor
              },
              ...defaultEdgeProperty
            };
          }

          // is advisor
          return {
            ...defaultMainEdgeProperty,
            sourceHandle: 'bottom',
            label: ['adv'],
            hidden: filters.find(filter => (filter.id === 'advisors'))?.active,
            data: {
              ...defaultDataProperty,
              labelEdgeColor: labelColor
            },
            ...defaultEdgeProperty

          };

        }
        // representative / represented companies
        if (companie.links[0]?.type === 'has.representative') {
          // entity2 is a parent company
          return {
            ...defaultMainEdgeProperty,
            source: String(linkedCompanieId),
            target: '1',
            hidden: filters.find(filter => (filter.id === 'parent'))?.active,
            data: {
              ...defaultDataProperty,
              labelEdgeColor: labelColor
            },
            ...defaultEdgeProperty
          };
        }

        // entity2 is a children company
        return {
          ...defaultMainEdgeProperty,
          sourceHandle: 'right',
          hidden: filters.find(filter => (filter.id === 'children'))?.active,
          data: {
            ...defaultDataProperty,
            display: 'right',
            labelEdgeColor: labelColor
          },
          ...defaultEdgeProperty

        };
      });
      let actualSharePercentage = -1;

      linksToPersons.forEach((person) => {
        if (( person.links[0].type === 'has.shareholder' || person.links[0].details?.positionTypes?.includes('sha'))) {
          actualSharePercentage = getHighestPercentageShare(person);
        }
      });

      const warningShaEdge = (actualSharePercentage === -1 || actualSharePercentage > 0) && shareholderTotalPercentage < 99.5 && props.entity.entityType.includes('Company') ? {
        id: `e1-${linksToCompanies.length + linksToPersons.length + 2}`,
        source: String(linksToCompanies.length + linksToPersons.length + 2),
        sourceHandle: 'right',
        target: '1',
        data: {
          ...defaultDataProperty,
          display: 'right',
          arrowAt: 'end'
        },
        ...defaultEdgeProperty,
        linkTo: 'warningSha'
      } : null;

      return ( warningShaEdge ? [...edgeToKeep, ...newCompaniesEdge, warningShaEdge] : [...edgeToKeep, ...newCompaniesEdge]);
    });
  }, [linksToCompanies, isPersonInitialized, companiesData, companiesLinks]);

  useEffect(() => {
    console.log('Filters updated:', filters);
    filtersRef.current = filters;

    setNodes((previousState: NodeType[]) => previousState.map((node: NodeType) => {
      if (node.data.filtersId) {
        const actualFilterOn = node.data.filtersId.find(filterId => filters.find(filter => filter.id === filterId)?.active);

        return {
          ...node,
          hidden: actualFilterOn !== undefined
        };
      }

      return node;
    }));
    setEdges((previousState: EdgeType[]) => previousState.map((edge: EdgeType) => {
      if (edge.data?.filtersId) {
        const actualFilterOn = edge.data.filtersId.find(filterId => filters.find(filter => filter.id === filterId)?.active);

        return {
          ...edge,
          hidden: actualFilterOn !== undefined
        };
      }

      return edge;
    }));
  }, [filters]);

  useEffect(() => {
    sortListRef.current = sortList;
  }, [sortList]);

  useEffect(() => {
    settingsListRef.current = settingsList;
    // TODO: Fix this, tmp solution :Has to set this to solve state issue //
    setNodes((previousState: NodeType[]) => previousState.map((node: NodeType) => node));
    setEdges((previousState: EdgeType[]) => previousState.map((edge: EdgeType) => edge));
  }, [settingsList]);

  useEffect(() => {
    console.log('Nodes updated:', nodes);
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    console.log('Edges updated:', edges);
  }, [edges]);

  useEffect(() => {
    const errorHandler = (e: ErrorEvent) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications'
        )
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges(eds => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const ConvertToCSV = (nodesToCsv: NodeType[]) => {
    const csvRows = [];
    const maxCompaniesInCommon = Math.max(...nodesToCsv.map(node => node.data.entitie?.links[0]?.details?.nbCompaniesInCommon || 0));
    const headers = [
      t('csvHeader.columns.id'),
      t('csvHeader.columns.type'),
      t('csvHeader.columns.label'),
      t('csvHeader.columns.isEntityActive'),
      t('csvHeader.columns.positionTypes'),
      t('csvHeader.columns.relatedto'),
      t('csvHeader.columns.typeOfRelation'),
      t('csvHeader.columns.knownShares'),
      t('csvHeader.columns.proxemmee'),
      t('csvHeader.columns.listedCompany'),
      t('csvHeader.columns.activitySector'),
      t('csvHeader.columns.legalForm'),
      t('csvHeader.columns.companyInCommon'),
      ...Array.from({ length: maxCompaniesInCommon }, (__, i) => `Companie ${i + 1}` || '_')
    ];

    csvRows.push(headers.join(';'));

    nodesToCsv.forEach((node: NodeType, index: number) => {
      const nodeIsParentOrChildren = (node.nodeFromType || node.data.nodeFromType || '_');
      const previousClusterName = index > 0 ? `${(nodesToCsv[index - 1].data.parentNode?.data.label || node.data.nodeNameFrom || '_').replace(/;/g, ' ')}` : '';
      if (index > 0 && previousClusterName !== `${(node.data.parentNode?.data.label || node.data.nodeNameFrom || '_').replace(/;/g, ' ')}`) {
        csvRows.push([]);
      }
      const isCompany = node.type === 'companieEntitie';
      // const currentGetter = node.data.entityData?.find(elem => elem?.getterProps.data.name === node.data.label);
      if (node.type === 'warningShaParent') return;

      const row = [
        node.data.entitie?.entity2.entityId.replace(/;/g, ' ') || node.data.entityId?.replace(/;/g, ' ') || '_',
        node.data.entitie?.entity2.entityType || node.data.entityType || '_',
        node.data.label?.replace(/;/g, ' '),
        node.data.entitie?.links[0]?.active || '_',
        node.data.entitie?.links[0]?.details?.positionTypes?.join(', ').replace(';', ' - ') || node.data.labelBetweenPerson?.join(', ').replace(';', ' - ') || '_',
        `${(node.data.parentNode?.data.label || node.data.nodeNameFrom || '_').replace(/;/g, ' ')}`,
        `${ nodeIsParentOrChildren === 'children' ?  'subsidiary' : nodeIsParentOrChildren}`,
        node.data.entitie?.links[0]?.details?.positionTypes?.includes('sha') ? getHighestPercentageShare(node.data.entitie) < 0 ? 0 : getHighestPercentageShare(node.data.entitie) : '_',
        `${node.data.prxmee || '_'}`, // Add this for category name  ${node.data.entitie?.relations[0]?.details?.prxmee?.level?.code}
        companiesData.find(company => company.name === node.data.entitie?.entity2?.name)?.tags?.some(elem => elem.value === 'listedCompany') || '_',
        companyGetter[index - 1] !== undefined && isCompany ? (companyGetter[index - 1]?.getActivitySector() || '_').replace(/;/g, ' ') : '_',
        companyGetter[index - 1] !== undefined  && isCompany ? companyGetter[index - 1]?.getLegalForm().replace(/;/g, ' ') : '_',
        node.data.entitie?.links[0].details?.nbCompaniesInCommon || '_',
        node.data.entitie?.links[0].details?.companies?.map(company => company?.companyRef?.name).join(', ').replace(/;/g, ' ') || '_'
      ];
      csvRows.push(row.join(';'));
    });

    return csvRows.join('\n');
  };

  const downloadCSV = (csvString: BlobPart, fileName = `NetworkChart_${mainEntityNode.data.label}.csv`) => {
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Stack display='flex' width='100%' height='75vh'>
        <Stack direction='row' spacing={4} pb={4} style={ isFullscreen ? { ...filterOnFullscreenStyle, ...{ left: 0 } } : filterBaseStyle }>
          <Stack display='flex' direction='row' alignItems='center' spacing={2}>
            <SimpleDisplaySwitch settingsList={settingsList} setSettingsList={setSettingsList} />
            {props.nbRelations < 200 &&
              <FullscreenButton />
            }
          </Stack>
          <SimpleDisplaySort sortList={sortList} setSortList={setSortList} />
          <SimpleDisplayFilters filters={filters} setFilters={setFilters} buttonLabel={t('filters.addFilter')} />
        </Stack>
        { props.nbRelations < 200 ?
          <div style={ isFullscreen ? ElementOnFullscreenStyle : ElementBaseStyle } onWheel={handleWheel}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={memoizedNodeTypes}
              edgeTypes={memoizedEdgeTypes}
              onEdgesChange={onEdgesChange}
              onNodeMouseEnter={(__, node: { id: string }) => {
                setEdges((previousState) => {
                  const newEdges = previousState.map((edge: EdgeType) => {
                    if (edge.target === node.id || edge.source === node.id) {
                      return {
                        ...edge,
                        data: {
                          ...edge.data,
                          highlight: {
                            color: '#00ab16'
                          }
                        }
                      };
                    }

                    return edge;
                  });

                  return newEdges;
                });
              } }
              onNodeMouseLeave={() => {
                setEdges(previousState => previousState.map((edge: EdgeType) => ({
                  ...edge,
                  data: {
                    ...edge.data,
                    highlight: undefined
                  }
                })
                ));
              } }
              isValidConnection={() => true}
              defaultViewport={{ x: 80, y: 0, zoom: 0.7 }}
              preventScrolling={false}
              minZoom={0.001}
              snapToGrid
              snapGrid={[15, 15]}
              proOptions={{ hideAttribution: true }}
              onlyRenderVisibleElements
              ref={flowRef}
            >
              <Controls position='bottom-left' showFitView={false}  style={{ transform: 'scale(1.8)', width: 'auto', height: 'auto', bottom: '8.5vh' }}>
                <ControlButton onClick={() => {
                  fitView();
                }}>
                  <Tooltip title={t('common.utils.center')} arrow placement='top'>
                    <IconViewportTall size='1rem' />
                  </Tooltip>
                </ControlButton>
                <ControlButton onClick={() => {
                  fitView();
                  downloadRelationChartOnClick(flowRef, props.entity.name || '', 'NetworkChart');
                }}>
                  <Tooltip title={`${t('common.utils.downloadAs')} .PNG`} arrow placement='top'>
                    <IconFileDownload size='1rem' />
                  </Tooltip>
                </ControlButton>
                <ControlButton onClick={() => {
                  downloadCSV(ConvertToCSV(nodes as NodeType[]));
                }}>
                  <Tooltip title={`${t('common.utils.downloadAs')} .CSV`} arrow placement='top'>
                    <IconTableExport size='1rem' />
                  </Tooltip>
                </ControlButton>
                <ControlButton onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.stopPropagation();
                  setIsFullscreen(!isFullscreen);
                  fitView();
                }}>
                  <FullscreenButton noBorder />
                </ControlButton>
              </Controls>
            </ReactFlow>
          </div>
          :
          <UnloadableDueToEntityNumberComponent
            action={downloadCSV}
            nodes={nodes}
            convertToCsv={ConvertToCSV}
            onCsvDownload={onCsvDownload}
            isDataReadyToDownload={isDataReadyToDownload}
            triggerDataCalculationForCSV={triggerDataCalculationForCSV}
          />
        }
      </Stack>
    </>
  );
}

export default RelationsTree;
