import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Box, darken, Stack, Typography, useTheme } from '@mui/material';
import { IconAlertTriangle, IconLogout2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { CompositeItemSpec, SegmentationFilter } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Legend from './legends';
import { VennDiagramConfig, VennDiagramResult } from '../types';
import * as venn from '../../../../venn/venn';
import { BaseSegmentationProps } from '@/main/containers/segmentations/types';
import SegmentsVennDiagram from '@/main/containers/vennDiagram/builder/segments';

interface VennDataType {
  sets: string[],
  setsIds: string[],
  color: string[],
  id: string[],
  size: number
}

type VennSelectionType = d3.Selection<HTMLDivElement, VennDataType[], d3.BaseType, unknown>

export interface VennDiagramProps {
  diagram: VennDiagramConfig,
  result: VennDiagramResult[],
  filters: SegmentationFilter[],
  setFilters: (filters: BaseSegmentationProps['filters']) => void,
  addFilter: (filter: BaseSegmentationProps['filters'][0]) => void,
  removeFilter: () => void,
  width?: number,
  height?: number,
  setData: (diagram: VennDiagramConfig | undefined) => void,
  updateDiagram: () => void
}

export function VennDiagram(props: VennDiagramProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const vennRef = useRef<HTMLDivElement>(null);
  const [vennTarget, setVennTarget] = useState<VennSelectionType>();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [tooltipData, setTooltipData] = useState<VennDataType>();
  const [tooltipPosition, setTooltipPosition] = useState<{x: number, y: number}>();
  const [initializeByChart, setInitializeByChart] = useState<boolean>(false);
  const [isSegmentsOrdering, setIsSegmentsOrdering] = useState(props.diagram.segments.length === 0);

  const generateAndAddFilter = (selectedSegments: string[]) => {
    const segments = props.diagram.segments.filter(segment => selectedSegments.includes(segment.label));

    if (segments.length > 0) {
      props.addFilter({
        id: segments.map(segment => segment.id).join(','),
        label: segments.map(segment => segment.label).join(' & '),
        type: segments.length > 0 ? 'and' : segments[0]?.behavior === 'intersection' ? 'and' as const : 'or' as const,
        on: props.diagram?.objectType === 'deecPerson' ? 'person1' as const : 'company' as const,
        subItemSpecs: segments.length > 0 ?
          segments.map((segment, index) => ({
            id: `${segment.id}_${index}`,
            type: segment.behavior === 'intersection' ? 'and' as const : 'or' as const,
            on: props.diagram?.objectType === 'deecPerson' ? 'person1' as const : 'company' as const,
            subItemSpecs: segment.groupIds.map((groupDescId, i) => ({
              id: `${groupDescId}_${segment.id}_${i}`,
              filterId: props.diagram?.objectType === 'deecPerson' ? 'person_groupMember' : 'company_groupMember',
              type: 'filter',
              on: props.diagram?.objectType === 'deecPerson' ? 'person1' as const : 'company' as const,
              values: [groupDescId]
            }))
          })) : segments[0].groupIds.map((groupDescId, index) => ({
            id: `${groupDescId}_${segments[0].id}_${index}`,
            filterId: props.diagram?.objectType === 'deecPerson' ? 'person_groupMember' : 'company_groupMember',
            type: 'filter',
            on: props.diagram?.objectType === 'deecPerson' ? 'person1' as const : 'company' as const,
            values: [groupDescId]
          }))
      } as BaseSegmentationProps['filters'][0]);
    }
  };

  const mapIdToLabel = (segment: string) => `${props.diagram.segments.find(diagSegm => diagSegm.id === segment)?.label}`;
  const mapIdToGroupId = (segment: string) => `${props.diagram.segments.find(diagSegm => diagSegm.id === segment)?.groupIds}`;
  const mapIdToColor = (segment: string) => `${props.diagram.segments.find(diagSegm => diagSegm.id === segment)?.color}`;
  const mapIdToSegmentId = (segment: string) => `${props.diagram.segments.find(diagSegm => diagSegm.id === segment)?.id}`;

  const modifyBackgroundColor = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('path')
      .style('fill-opacity', 0.5)
      .style('fill', (d) => {
        const setColors = (d as VennDataType).sets.map((set: string) => {
          const segment = props.diagram.segments.find(seg => seg.label === set);

          return segment?.color || '#808080';
        });

        return setColors.length > 0 ? setColors.toString() : 'grey';
      });
  };

  const modifyLabelColor = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('text')
      .style('fill', 'black');
  };

  const initMouseEventHandler = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .on('mouseover', (event, d) => {
        venn.sortAreas(vennSelection, d);
        setIsHovered(true);
        setTooltipData(d);
        if (event.currentTarget) {
          d3.select(event.currentTarget).select('path')
            .style('stroke-width', 1.5)
            .style('stroke', d.sets.length > 1 ? 'white' : darken(d.color[0], 0))
            .style('stroke-opacity', 1);
        }
      })
      .on('mousemove', (event) => {
        setTooltipPosition({ x: event.screenX, y: event.screenY });
      })
      .on('mouseout', (event, d) => {
        const labels = d.setsIds.map((label: string) => label).join(',');
        const isActive = props.filters.map(filter => (filter as CompositeItemSpec).subItemSpecs?.map(subItem => (subItem.values ? subItem.values : (subItem as CompositeItemSpec).subItemSpecs?.map(subItem2 => subItem2.values).flat(1)))).flat(2).join(',') === labels;

        setIsHovered(false);
        setTooltipData(undefined);
        d3.select(event.currentTarget).select('path')
          .style('stroke-width', isActive ? 1 : 0)
          .style('stroke', isActive ? 'black' : 'none')
          .style('stroke-opacity', isActive ? 1 : 0);
      }
      );
  };

  const onClickEventHandler = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g').on('click', (event, d) => {
      const labels = d.setsIds.map(label => label).join(',');
      const isActive = props.filters.map(filter => (filter as CompositeItemSpec).subItemSpecs?.map(subItem => (subItem.values ? subItem.values : (subItem as CompositeItemSpec).subItemSpecs?.map(subItem2 => subItem2.values).flat(1)))).flat(2).join(',') === labels;

      d3.select(event.currentTarget).select('path')
        .style('fill-opacity', isActive ? 0.9 : 1)
        .style('stroke-width', isActive ? 1 : 0)
        .style('stroke', isActive ? 'black' : 'none');

      if (!isActive) {
        setInitializeByChart(true);
        generateAndAddFilter(d.sets);
      } else {
        setInitializeByChart(false);
        props.removeFilter();
      }
    });
  };

  const modifyIntersectionNodeStyle = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('path')
      .each((d, index, nodes) => {
        if ((d as VennDataType).sets.length > 1) {
          d3.select(nodes[index])
            .style('stroke-opacity', 1)
            .style('fill-opacity', 0);
        }
      });
  };

  const modifyCursorPointer = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('path')
      .style('cursor', 'pointer');
  };

  const modifyChartStyleOnLegendClick = (vennSelection: VennSelectionType) => {
    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('path')
      .each((d, index, nodes) => {
        const labels = (d as VennDataType).setsIds.map(label => label).join(',');
        const isActive = props.filters.map(filter => (filter as CompositeItemSpec).subItemSpecs?.map(subItem => (subItem.values ? subItem.values : (subItem as CompositeItemSpec).subItemSpecs?.map(subItem2 => subItem2.values).flat(1)))).flat(2).join(',') === labels;

        if (isActive) {
          d3.select(nodes[index])
            .style('stroke-width', 1)
            .style('stroke', 'black')
            .style('stroke-opacity', 1);
        } else {
          d3.select(nodes[index])
            .style('stroke-width', isActive ? 1 : 0)
            .style('stroke', isActive ? 'black' : 'none')
            .style('stroke-opacity', isActive ? 1: 0);
        }
      });
  };

  const adjustLabelOverlaping = (vennSelection: VennSelectionType) => {
    const overlapFixed = [''];

    vennSelection.selectAll<SVGGElement, VennDataType>('g')
      .selectAll('text')
      .each((d, index, nodes) => {
        const currentNode = d3.select(nodes[index]);
        const currentText = currentNode.text().trim();

        if (currentText) {
          const currentX = parseFloat(currentNode.attr('x') || '0');
          let currentY = parseFloat(currentNode.attr('y') || '0');

          // Iterate over all previous tspans to check for overlap but index is always 0 but it is incremented correctly in the loop with this method
          vennSelection.selectAll<SVGGElement, VennDataType>('g')
            .selectAll('text')
            .each((_, innerIndex, innerNodes) =>  {
              const otherNode = d3.select(innerNodes[innerIndex]);
              const otherText = otherNode.text().trim();

              if (otherText && otherText !== currentText && !overlapFixed.includes(otherText)) {
                const otherX = parseFloat(otherNode.attr('x') || '0');
                const otherY = parseFloat(otherNode.attr('y') || '0');

                if (Math.abs(currentX - otherX) < 50 && Math.abs(currentY - otherY) < 10) {
                  const numberOfTextRows = currentNode.selectAll('tspan').size();
                  const offset = 15;

                  currentY += numberOfTextRows * offset;
                  overlapFixed.push(currentText);
                } else if (Math.abs(currentX - otherX) < 50 && Math.abs(currentY - otherY) < 50) {
                  // const numberOfTextRows = currentNode.selectAll('tspan').size();
                  // const offset = 15;
                  // Condition to check if there is nearby text or which is the closest to the current text
                  // then, currentY should take + or - to move up or down
                  // currentY += numberOfTextRows * offset;
                  // overlapFixed.push(currentText);
                }
              }
            });

          currentNode.selectAll('tspan').attr('y', currentY);
        }
      });
  };

  const chartStyleAndActionHandler = (vennSelection: VennSelectionType) => {
    modifyBackgroundColor(vennSelection);
    modifyLabelColor(vennSelection);
    initMouseEventHandler(vennSelection);
    onClickEventHandler(vennSelection);
    modifyIntersectionNodeStyle(vennSelection);
    modifyCursorPointer(vennSelection);
    modifyChartStyleOnLegendClick(vennSelection);
    adjustLabelOverlaping(vennSelection);
  };

  const initializeVennDiagram = (result: VennDiagramResult[]) => {
    if (vennRef.current) {
      const chart = venn.VennDiagram();
      const uniqueSegments = new Set(
        result.flatMap(population => population.segments)
      );
      const sets = props.result
        .map(population => ({
          sets: population.segments.map(segment => mapIdToLabel(segment)),
          setsIds: population.segments.map(segment => mapIdToGroupId(segment)),
          color: population.segments.map(segment => mapIdToColor(segment)),
          id: population.segments.map(segment => mapIdToSegmentId(segment)),
          size: population.count
        }))
        .filter(population => population.sets.length > 0);

      // Set unique segment to 0 if it is not defined
      uniqueSegments.forEach((segment) => {
        const segmentExists = sets.some(set => set.id.length === 1 && set.id.includes(segment));
        if (!segmentExists) {
          sets.push({
            sets: [mapIdToLabel(segment)],
            setsIds: [mapIdToGroupId(segment)],
            color: [mapIdToColor(segment)],
            id: [mapIdToSegmentId(segment)],
            size: 0
          });
        }
      });

      // chart.scaleToFit(50);
      chart.minCircleRadius(50);
      chart.height(props.height || 750);
      chart.width(props.width || 800);

      // Create venn diagram chart here
      const vennSelection = d3.select(vennRef.current)
        .datum(sets)
        .call(chart);
      setVennTarget(vennSelection);
    }
  };

  useEffect(() => {
    initializeVennDiagram(props.result);
  }, [props.result]);

  useEffect(() => {
    if (props.filters.length === 0) {
      setInitializeByChart(false);
    }
    if (vennTarget) {
      chartStyleAndActionHandler(vennTarget);
    }
  }, [vennTarget, props.filters]);

  return (
    <>
      {isHovered && tooltipData && tooltipPosition &&
        <Stack
          height='35px'
          width='min-content'
          bgcolor='white'
          display='flex'
          flexDirection='row'
          justifyContent='center'
          alignItems='center'
          borderRadius={0.5}
          position='absolute'
          p={2}
          // TODO: add scrollY position to top : style={{ top: tooltipPosition.y - 450, left: tooltipPosition.x - 440 }}>
          // Temporary position while fixing the scroll position issue (cannot be retrieve)
          style={{ top: '15%', left: '1%' }}>
          {tooltipData.sets.map((set, index) => (
            <Stack direction='row' alignItems='center' mr={2}>
              <Box
                width='20px'
                height='20px'
                bgcolor={tooltipData.color[index]}
                borderRadius={1}
                mr={1}
              />
              <Typography color={tooltipData.color[index]} sx={{ maxHeight: '35px', lineHeight: '35px', flexGrow: 1, whiteSpace: 'nowrap', paddingLeft: 2 }}>{set}</Typography>
            </Stack>
          ))}
          <Typography fontWeight={500} sx={{ maxHeight: '35px', lineHeight: '35px', flexGrow: 1, whiteSpace: 'nowrap', paddingLeft: 6 }}>
            {`${tooltipData.size} entities`}
          </Typography>
        </Stack>
      }
      {isSegmentsOrdering ?
        <Box pl={4} pr={4}>
          <Button
            variant='outlined'
            onClick={() => {
              setIsSegmentsOrdering(false);
              props.updateDiagram();
            }}
            startIcon={<IconLogout2 size='1.6rem' />}
            fullWidth
          >
            {t('segmentation.render.segmentationCriterias.closeEditMode')}
          </Button>
        </Box> :
        null
      }
      {isSegmentsOrdering ?
        <SegmentsVennDiagram
          data={props.diagram}
          setData={props.setData}
          noAccordion
        /> :
        <Stack display='flex' direction='row' alignContent='center' alignItems='center' overflow='auto'>
          <Stack flexBasis='60%' height='100%' ref={vennRef} />
          <Grid container flexBasis='40%'>
            <Legend
              {...props}
              mapIdToLabel={mapIdToLabel}
              mapIdToColor={mapIdToColor}
              filters={props.filters}
              generateAndAddFilter={generateAndAddFilter}
              removeFilter={props.removeFilter}
              initializeByChart={initializeByChart}
              isSegmentsOrdering={isSegmentsOrdering}
              setIsSegmentsOrdering={setIsSegmentsOrdering}
            />
          </Grid>
        </Stack>
      }

      {venn.areaError.length > 0 &&
        <Stack direction='row' p={4} spacing={2} display='flex' justifyContent='center' alignItems='center'>
          <IconAlertTriangle color={theme.palette.warning.main} />
          <Typography fontSize={16} flexBasis='50%'>
            {`${t('segmentation.vennDiagram.display.areaError.part1')}`} <IconAlertTriangle color={theme.palette.warning.main} size='1rem'/> {`${t('segmentation.vennDiagram.display.areaError.part2')}`}
          </Typography>
        </Stack>
      }
    </>
  );
}

export default VennDiagram;
