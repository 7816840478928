import React from 'react';
import { IconChartInfographic, IconPlus } from '@tabler/icons-react';
import { Navigate } from 'react-router-dom';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../../config/dna.config.json';
import SegmentationBuilder from '../../../../containers/segmentations/builder';
import SegmentationWrapper from '../../../../containers/segmentations/wrapper';

const routesSegmentationFactory = createElementRoutes({
  id: dnaConfig.routes.admin.factory.segmentation.label,
  path: dnaConfig.routes.admin.factory.segmentation.baseUrl,
  slug: 'access',
  slugSuffix: true,
  icon: IconChartInfographic,
  component: <Navigate to={dnaConfig.routes.admin.factory.segmentation.create.baseUrl} />,
  idComponent: <SegmentationWrapper>
    <SegmentationBuilder />
  </SegmentationWrapper>,
  childrens: [
    createElementRoutes({
      id: dnaConfig.routes.admin.factory.segmentation.create.label,
      path: dnaConfig.routes.admin.factory.segmentation.create.baseUrl,
      icon: IconPlus,
      component: <SegmentationWrapper>
        <SegmentationBuilder />
      </SegmentationWrapper>
    })
  ]
});

export default routesSegmentationFactory;
