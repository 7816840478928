import React, { Dispatch, SetStateAction } from 'react';
import { SegmentationPossibleOutputEntityTypes } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { CustomSegmentationData } from '../../types';
import { possibleDataSetIds } from '../../builder/workflows';
import Selector from '@/components/selector';

interface SelectorLevelsRenderSegmentationsProps {
  possibleOutputEntities: CustomSegmentationData['possibleOutputEntities'],
  outputEntities: SegmentationPossibleOutputEntityTypes | undefined,
  setOutputEntities: (outputEntities: SegmentationPossibleOutputEntityTypes) => void,
  setDataSetId: Dispatch<SetStateAction<CustomSegmentationData['possibleDataSetIds'][0]>>,
  first?: boolean
}

function SelectorLevelsRenderSegmentations(props: SelectorLevelsRenderSegmentationsProps) {
  const { t } = useTranslation();

  return (
    <Selector
      options={
        props.possibleOutputEntities.map(possibleOutputEntity => ({
          label: t(`segmentation.builder.config.advanced.on.${possibleOutputEntity}`).split(':')[0],
          value: possibleOutputEntity,
          tooltip: t(`segmentation.builder.config.advanced.on.${possibleOutputEntity}`).split(':')[1]
        }))
      }
      option={props.outputEntities}
      setOption={(possibleOutputEntity) => {
        props.setDataSetId(
          possibleDataSetIds([possibleOutputEntity as SegmentationPossibleOutputEntityTypes])[0]
        );
        props.setOutputEntities(possibleOutputEntity as SegmentationPossibleOutputEntityTypes);
      }}
      inverted={!props.first}
    />
  );
}

export default SelectorLevelsRenderSegmentations;
