import React, { createContext, ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { SegmentationData, SegmentationRequest } from '@deecision/dna-interfaces';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { cloneDeep, lowerCase } from 'lodash';
import { makeFindOptions } from '@/utils';
import { BaseCustomUserData } from '../types';
import SegmentationsServices from '../services';
import getDynamicGroupsRequest from '../../../modules/deetect/portfolios/utils/dynamicgroups';

export const SegmentationDataContext = createContext<SegmentationData | null>(null);

function AbstractSegmentation(props: { entityType?: SegmentationData['objectType'], withEntities?: boolean, loadingPage?: ReactElement, children: ReactElement }): ReactElement {
  const userData = useLoaderData() as { data?: BaseCustomUserData };
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const segmentationService = new SegmentationsServices();
  const [data] = useState<BaseCustomUserData | undefined>(userData?.data? cloneDeep(userData?.data) : undefined);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [entryCount, setEntryCount] = useState<number>(0);
  const [entityType, setEntityType] = useState(props.entityType || searchParams.get('entityType'));
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const outputEntitiesFromParams = searchParams.get('outputEntities');
  const workflowIdFromParams = searchParams.get('workflowId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const dataSetId = (lowerCase(entityType || '')?.includes('person') || data?.data.on?.includes('person'))
    ? 'deecPersons'
    : 'deecCompanies';
  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];
  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === 'true' ? true : filterValues[index] === 'false' ? false : filterValues[index]]
  })) : [];

  const performSegmentation = () => {
    const workflowId = (lowerCase(entityType || '')?.includes('person') || data?.data.on?.includes('person')) ?'person1_link_person2' : 'simple_company';
    const request = {
      dataSetId,
      outputEntities: outputEntitiesFromParams as SegmentationRequest['outputEntities'] || 'person1',
      workflowId: workflowIdFromParams as SegmentationRequest['workflowId'] || workflowId,
      filters: [
        ...baseFilterGroupMember,
        ...baseFiltersFromUrlParams,
        ...(data?.data.preFilters?.map(preFilter => ({ ...preFilter, id: preFilter.filterId })) as SegmentationRequest['filters'] || [])
      ],
      globalFilteringItems: [],
      segmentationCriterias: [],
      entitiesSettings: {
        includeEntities: !!props.withEntities,
        findOptions: makeFindOptions({ searchParams: props.withEntities ? searchParams : undefined, post: true })
      }
    };

    segmentationService.perform(request)
      .then((res) => {
        setSegmentationData(res.data ? { ...res.data, request } : undefined);
        if (!entryCount && res.data) {
          setEntryCount(res.data.totalCount);
        }
      });
  };

  useEffect(() => {
    performSegmentation();
  }, [id, entityType]);

  useEffect(() => {
    if (props.withEntities && segmentationData) {
      performSegmentation();
    }
  }, [searchParams]);

  useEffect(() => {
    setEntityType(props.entityType || searchParams.get('entityType'));
  }, [props.entityType]);

  return (segmentationData ?
    <SegmentationDataContext.Provider value={segmentationData}>
      {props.children}
    </SegmentationDataContext.Provider> :
    props.loadingPage || <></>
  );
}

export default AbstractSegmentation;
