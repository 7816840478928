import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Nullable } from '@deecision/deecision-interfaces';
import { VennDiagramConfig } from '@/main/containers/vennDiagram/types';
import { CustomUserDataAccess } from '@/main/containers/segmentations/types';
import VennDiagramServices from '@/main/containers/vennDiagram/services';

const defaultNewCustomVennDiagramTitle = 'My Venn Diagram';
export const VennDiagramContext = createContext<Nullable<{
  vennDiagram?: VennDiagramConfig,
  setVennDiagram: Dispatch<SetStateAction<VennDiagramConfig | undefined>>,
  save: (accessTmp: CustomUserDataAccess, asNew?: boolean) => void,
  deleteVennDiagram: () => void,
  updateAccess: (accessTmp: CustomUserDataAccess) => void
    }>>(null);

function VennDiagramWrapper(props: { children: ReactElement }): ReactElement {
  const { vennDiagramId, id } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const keycloak = useKeycloak();
  const [vennDiagram, setVennDiagram] = useState<VennDiagramConfig>();

  const save = useCallback((accessTmp: CustomUserDataAccess, asNew?: boolean) => {
    const dataService = new VennDiagramServices<VennDiagramConfig>();

    if (vennDiagram) {
      vennDiagram.lastUpdate = new Date();
      vennDiagram.ownerType = accessTmp;

      if (asNew) {
        delete vennDiagram._id;

        vennDiagram.ownerId = keycloak.keycloak.tokenParsed?.preferred_username;

        dataService.post?.(vennDiagram)
          .then((res) => {
            setVennDiagram(res.data);
            navigate({
              pathname: pathname
                .replace(vennDiagramId || id || 'create', res.data?._id || 'create'),
              search
            });
          });
      } else if (vennDiagram._id) {
        dataService.put?.(vennDiagram._id, vennDiagram)
          .then(res => setVennDiagram(res.data));
      }
    }
  }, [keycloak.keycloak.tokenParsed?.preferred_username, vennDiagram]);

  const updateAccess = (accessTmp: CustomUserDataAccess) => {
    if (vennDiagram?._id) {
      save(accessTmp);
    } else {
      setVennDiagram(prevState => (prevState ? { ...prevState, ownerType: accessTmp } : undefined));
    }
  };

  const deleteVennDiagram = () => {
    const dataService = new VennDiagramServices<VennDiagramConfig>();

    if (vennDiagram?._id) {
      dataService.delete?.(vennDiagram?._id);
    }
  };

  const getVennDiagram = () => {
    const dataService = new VennDiagramServices<VennDiagramConfig>();

    if (vennDiagramId || id) {
      dataService.get?.(vennDiagramId || id || '')
        .then(res => setVennDiagram(res.data));
    }
  };

  useEffect(() => {
    if ((vennDiagramId || id) && !vennDiagram && !pathname.includes('create')) {
      getVennDiagram();
    } else {
      setVennDiagram({
        ownerType: 'me',
        ownerId: keycloak.keycloak.tokenParsed?.preferred_username,
        label: defaultNewCustomVennDiagramTitle,
        objectType: 'deecPerson',
        date: new Date(),
        lastUpdate: new Date(),
        segments: []
      });
    }
  }, [vennDiagramId || id]);

  const vennDiagramContextValue = useMemo(() => ({ vennDiagram, setVennDiagram, save, deleteVennDiagram, updateAccess }), [vennDiagram]);

  return (
    <VennDiagramContext.Provider value={vennDiagramContextValue}>
      {props.children}
    </VennDiagramContext.Provider>
  );
}

export default VennDiagramWrapper;
