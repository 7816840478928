import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as venn from '../../../../../venn/venn';

interface VennGenericDataType {
  sets: string[],
  size: number
}

type VennGenericSelectionType = d3.Selection<HTMLDivElement | null, VennGenericDataType[], null, unknown>

type GenericSegmentProps = {
  behavior: 'intersection' | 'union',
  color: string,
  height?: number,
  width?: number
}

function GenericSegmentVennDiagram(props: GenericSegmentProps) {
  const vennRef = useRef<HTMLDivElement>(null);
  const [vennTarget, setVennTarget] = useState<VennGenericSelectionType>();
  const genericData = [{
    sets: ['A'],
    size: 2
  }, {
    sets: ['B'],
    size: 2
  }, {
    sets: ['A', 'B'],
    size: 1
  }];

  const modifyBackgroundColor = (vennSelection: VennGenericSelectionType) => {
    const behaviorLength = props.behavior === 'intersection' ? 1 : 0;

    vennSelection.selectAll<SVGGElement, VennGenericDataType>('g')
      .selectAll('path')
      .style('fill-opacity', 0.5)
      .style('fill', d => ((d as VennGenericDataType).sets.length > behaviorLength ? props.color : '#B4C3D0'));
  };

  useEffect(() => {
    const chart = venn.VennDiagram();
    const sets = genericData
      .map(genericIdx => ({
        sets: genericIdx.sets,
        size: genericIdx.size
      }))
      .filter(population => population.sets.length > 0);
    chart.height(props.height || 300);
    chart.width(props.width || 300);

    // Create venn diagram chart here
    const vennSelection = d3.select(vennRef.current)
      .datum(sets)
      .call(chart);
    setVennTarget(vennSelection);
  }, []);

  useEffect(() => {
    if (vennTarget) {
      modifyBackgroundColor(vennTarget);
    }
  }, [vennTarget, props.behavior, props.color]);

  return (
    <div ref={vennRef} />
  );
}

export default GenericSegmentVennDiagram;
