import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export type CustomDialogProps = {
  open: boolean,
  handleClose: () => void,
  title: string,
  content: string,
  buttonConfirmationText?: string,
  buttonCancelText?: string,
  buttonConfirmationAction?: () => void,
  buttonCancelAction?: () => void,
  displayOnlyConfirmationButton?: boolean,
  desctructive?: boolean,
  size?: 'small' | 'medium' | 'large'
};

function CustomDialog(props: CustomDialogProps) {

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle id='alert-dialog-title' sx={{ fontSize: '1.2rem' }}>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' sx={{ fontSize: '1rem' }}>
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.displayOnlyConfirmationButton &&
          <Button variant='text' onClick={props.buttonCancelAction || props.handleClose}>
            {props.buttonCancelText || 'No'}
          </Button>
        }
        <Button
          variant='outlined'
          onClick={props.buttonConfirmationAction || props.handleClose}
          autoFocus
          sx={{
            color: props.desctructive ? 'error.main' : 'primary.main',
            '&:hover': {
              borderColor: props.desctructive ? 'error.main' : 'primary.main',
              color: props.desctructive ? 'error.main' : 'primary.main'
            }
          }}
        >
          {props.buttonConfirmationText || 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
