import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import {
  IconAlertTriangle,
  IconUsers,
  IconBuildings
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../../../components/accordion';
import { BaseItemVennDiagramBuilderProps } from '../../types';
import TextField from '@mui/material/TextField';
import { CustomUserDataAccess } from '@/main/containers/segmentations/types';
import { EntityType } from '@deecision/dna-interfaces';

interface ConfigVennDiagramProps extends BaseItemVennDiagramBuilderProps {
  access: CustomUserDataAccess,
  setAccess: (access: CustomUserDataAccess) => void
}

function ConfigVennDiagram(props: ConfigVennDiagramProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const objectType = [{ type: 'deecPerson', icon: <IconUsers />, label: t('entities.persons.label') }, { type: 'deecCompany', icon: <IconBuildings />,  label: t('entities.companies.label') }];
  const [name, setName] = useState<string>();

  return (
    <CustomAccordion
      title={t('segmentation.builder.config.label')}
      bgcolor={theme.palette.background.default}
      defaultOpen
    >
      <Stack spacing={4} p={4} alignItems='flex-start'>
        <Stack width='100%'>
          <Typography variant='h5' p={2}>
            {t('segmentation.vennDiagram.builder.config.name')}
          </Typography>
          <TextField
            value={name || props.data?.label}
            fullWidth
            onChange={e => setName(e.target.value)}
            onFocus={() => setName(props.data.label)}
            onBlur={() => props.setData(props.data ? name ? { ...props.data, label: name } : props.data : undefined)}
          />
        </Stack>
        <FormControl required>
          <FormLabel id='controlled-radio-buttons-group-venn-entityType-label'>
            {t('segmentation.vennDiagram.builder.config.types.label')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='controlled-radio-buttons-group-venn-entityType-label'
            name='controlled-radio-buttons-group-venn-entityType'
            value={props.data.objectType}
            onChange={(value) => {
              props.setData({ ...props.data, objectType: value.target.value as EntityType });
            }}
          >
            {objectType.map(type => (
              <FormControlLabel
                key={type.type}
                value={type.type}
                control={<Radio checked={props.data.objectType === type.type}/>}
                label={
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {type.icon}
                    <Typography variant='h5'>{t(`${type.label}`)}</Typography>
                  </Stack>
                }
                disabled={props.data.segments.length > 0}
              />
            ))}
          </RadioGroup>
          {props.data.segments.length > 0 &&
            <Stack display='flex' direction='row' justifyContent='center' alignItems='center' spacing={2}>
              <IconAlertTriangle color={theme.palette.warning.main} size='1.1rem' />
              <Typography fontSize={16}>{t('segmentation.vennDiagram.builder.initialize.warningDisabled')}</Typography>
            </Stack>
          }
          {!props.data.objectType && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
        </FormControl>

        {location.pathname.includes('/factory/') &&
          <FormControl>
            <FormLabel id='selectAccess'>{t('segmentation.vennDiagram.builder.config.access.label')}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={props.access}
              onChange={(e) => props.setAccess(e.target.value as CustomUserDataAccess)}
            >
              <FormControlLabel value='me' control={<Radio />} label={t('segmentation.builder.config.access.me')} />
              <FormControlLabel value='admins' control={<Radio />} label={t('segmentation.builder.config.access.admins')} />
              <FormControlLabel value='everyone' control={<Radio />} label={t('segmentation.builder.config.access.everyone')} />
            </RadioGroup>
          </FormControl>
        }
      </Stack>
    </CustomAccordion>
  );
}

export default ConfigVennDiagram;
