import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { lighten, darken, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { IconCircleArrowDownFilled, IconCircleArrowUpFilled, IconEqual } from '@tabler/icons-react';
import { ConfeedenzAlertAxisData, ConfeedenzAxis, ConfeedenzAxisData, ConfeedenzCompanyTrustsAxisData, ConfeedenzOpportunitiesAxisData, ConfeedenzPersonTrustsAxisData } from '@deecision/dna-interfaces';

interface WidgetsRadarDetails {
  yearN: ConfeedenzAxisData[],
  yearNMinus1: ConfeedenzAxisData[],
  entityType: 'deecCompany' | 'deecPerson'
}

function WidgetsRadarDetail(props: WidgetsRadarDetails): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [displayDetails, setDisplayDetails] = useState(false);

  const metadataLabelForAlerts = (params: ConfeedenzAlertAxisData | undefined) => {
    if (!params) {
      return '';
    }
    if (params.axis === ConfeedenzAxis.ALERT) {

      return (
        <>
          <Typography>{t('common.utils.total')}{t('prioreez.alert.label')}: {params.metadata.nbAlert}</Typography>
          {params.metadata.alert.map(alert =>
            <Typography>
              {/* Amelioration: put the name with a getEntity instead and put a navigate to the entity page */}
              {alert.entityRef.entityId}: {alert.alertType.map(alrtType => alrtType.type).join(',')}
            </Typography>
          )}
          <Typography>
            {params.metadata.sanction.map(sanction =>
              <Typography>
                {sanction.type} {t('common.utils.startingOn')}: {sanction.startDate}
              </Typography>
            )}
          </Typography>
        </>
      );
    }

    return '';
  };

  const metadataLabelForPersonTrust = (params: ConfeedenzPersonTrustsAxisData | undefined) => {
    if (!params) {
      return '';
    }
    if (params.axis === ConfeedenzAxis.TRUST) {
      return (
        <>
          <Typography>{t('common.utils.completeness')}: {params.metadata.completeness}</Typography>
          <Typography>{t('common.utils.nbLinkedCompanies')}: {params.metadata.nbLinkedCompanies}</Typography>
          <Typography>{t('common.utils.nbLinkedPersons')}: {params.metadata.nbLinkedPersons}</Typography>
          <Typography>{t('common.utils.meanDateOfCompaniesKeyFigures')}: {params.metadata.meanDateOfCompaniesKeyFigures}</Typography>
        </>
      );
    }

    return '';
  };

  const metadataLabelForCompanyTrust = (params: ConfeedenzCompanyTrustsAxisData | undefined) => {
    if (!params) {
      return '';
    }
    if (params.axis === ConfeedenzAxis.TRUST) {
      return (
        <>
          <Typography>{t('common.utils.completeness')}: {params.metadata.completeness}</Typography>
          <Typography>{t('common.financial.label')}: {params.metadata.financial}</Typography>
          <Typography>{t('common.utils.valuationCalc')}: {params.metadata.valuationCalc}</Typography>
          <Typography>{t('common.utils.parentCompanies')}: {params.metadata.owners?.parentCompanies?.map(parentCompanies =>
            <Typography>
              {parentCompanies.name} ({parentCompanies.entityId})
            </Typography>
          )}
          </Typography>
          <Typography>{t('common.utils.shareHolders')}: {params.metadata.owners?.shareholders?.map(shareholders =>
            <Typography>
              {shareholders.name} ({shareholders.entityId})
            </Typography>
          )}
          </Typography>
        </>
      );
    }

    return '';
  };

  const metadataLabelForOpportunities = (params: ConfeedenzOpportunitiesAxisData | undefined) => {
    if (!params) {
      return '';
    }
    if (params.axis === ConfeedenzAxis.OPPORTUNITIES) {
      return (
        <>
          <Typography>{t('common.utils.newLinkInCompany')}: {params.metadata.newLinkInCompany?.map(entityRef =>
            <Typography>
              {entityRef.name} ({entityRef.entityId})
            </Typography>
          )}
          </Typography>
          <Typography>{t('common.utils.newCompanyCreated')}: {params.metadata.newCompanyCreated?.map(entityRef =>
            <Typography>
              {entityRef.name} ({entityRef.entityId})
            </Typography>
          )}
          </Typography>
          <Typography>{t('common.utils.linkedCompanyWithSells')}: {params.metadata.linkedCompanyWithSells?.map(bodaccUrl =>
            <Typography>
              {bodaccUrl}
            </Typography>
          )}
          </Typography>
        </>
      );
    }

    return '';
  };

  const metadataLabelByAxis = (params: ConfeedenzAxisData, entityType: 'deecCompany' | 'deecPerson') => {
    // Tmp tricks to handle metadata typing
    const paramsWithMetadata = {
      ...params,
      metadata: params.metadata ? params.metadata : { completeness: '', financial: '', valuationCalc: 0, owners: { parentCompanies: [], shareholders: [] } }
    };
    const mapping = {
      [ConfeedenzAxis.COMPLETENESS]: params.reason,
      [ConfeedenzAxis.ALERT]: metadataLabelForAlerts(paramsWithMetadata),
      [ConfeedenzAxis.CONFORMITY]: params.reason,
      [ConfeedenzAxis.OPPORTUNITIES]: metadataLabelForOpportunities(paramsWithMetadata),
      [ConfeedenzAxis.TRUST]: entityType === 'deecCompany'
        ? metadataLabelForCompanyTrust(paramsWithMetadata)
        : metadataLabelForPersonTrust(paramsWithMetadata)
    };

    return mapping[params.axis];
  };

  const metadataChipsByYear = (params: ConfeedenzAxisData, actual: boolean, entityType: 'deecCompany' | 'deecPerson') => (
    <Box p={1} alignItems='center'>
      <Tooltip title={metadataLabelByAxis(params, entityType)} arrow placement='right' style={{ whiteSpace: 'pre-line' }}>
        <Box
          color={actual ? 'primary' : '#5959B4'}
          borderRadius={4}
          px={4}
          py={1}
          sx={{
            width: 'min-content',
            bgcolor: actual ? lighten('#5959B4', 0.5) : theme.palette.primary.light,
            color: actual ? darken('#5959B4', 0.3) : theme.palette.primary.dark,
            fontWeight: 500
          }}
        >
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            {`${t(`entities.persons.person.charts.radar.${params.axis}`)}: ${params.value || 0}`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );

  return (
    <>
      {!displayDetails &&
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          <Typography alignSelf='center' onClick={()=>setDisplayDetails(true)} sx={{ cursor: 'pointer', marginRight: 1 }}>
            {t('common.utils.show')} {t('common.utils.details')}
          </Typography>
          <IconCircleArrowDownFilled size='1rem' />
        </Box>
      }
      {displayDetails &&
        <>
          <Stack width='100%' direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              {props.yearNMinus1.map(params => metadataChipsByYear(params, true, props.entityType))}
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
              { props.yearN.length === props.yearNMinus1.length &&
                props.yearN.map( (yearN, index) =>
                  <Box p={1} height='2.5rem' display='flex' justifyContent='center' alignItems='center'>
                    {yearN.value > props.yearNMinus1[index].value &&
                      <Tooltip title={`+${yearN.value - props.yearNMinus1[index].value}`} arrow placement='top'>
                        <IconCircleArrowUpFilled size='1.3rem' color='green' />
                      </Tooltip>
                    }
                    {yearN.value === props.yearNMinus1[index].value &&
                      <Tooltip title={t('common.utils.noVariation')} arrow placement='top'>
                        <IconEqual size='1.3rem' />
                      </Tooltip>
                    }
                    {yearN.value < props.yearNMinus1[index].value &&
                      <Tooltip title={`${yearN.value - props.yearNMinus1[index].value}`} arrow placement='top'>
                        <IconCircleArrowDownFilled size='1.3rem' color='red' />
                      </Tooltip>
                    }
                  </Box>
                )
              }
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
              {props.yearN.map(params => metadataChipsByYear(params, false, props.entityType))}
            </Box>
          </Stack>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
            <Typography alignSelf='center' onClick={() => setDisplayDetails(false)} sx={{ cursor: 'pointer', marginRight: 1 }}>{t('common.utils.hide')} {t('common.utils.details')}</Typography>
            <IconCircleArrowUpFilled size='1rem' />
          </Box>
        </>
      }
    </>
  );
}

export default WidgetsRadarDetail;
