import React from 'react';
import { Chip, darken, lighten, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const filterClassificationColorMapping: { [key: string]: string } = {
  genericCriteria: '#2DB4B4', // secondary variant
  businessCriteria: '#73461E', // marron
  decisionCriteria: '#4D49A3' // violet
};

interface ClassificationChipProps {
  label: string
}

function ClassificationChip(props: ClassificationChipProps) {
  const { t } = useTranslation();
  const defaultGreyColor = '#808080';

  return (
    <Chip
      key={t(`segmentation.render.classifications.${props.label}`)}
      label={
        <Typography color={darken(filterClassificationColorMapping[props.label] || defaultGreyColor, 0.5 )}>
          {t(`segmentation.render.classifications.${props.label}`)}
        </Typography>
      }
      style={{ backgroundColor: lighten(filterClassificationColorMapping[props.label] || defaultGreyColor, 0.7 ) }}
      size='small'
    />
  );
};

export default ClassificationChip;