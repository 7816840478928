import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import {
  SegmentationData,
  SegmentationPossibleDisplayTypes,
  SegmentationPossibleOutputEntityTypes
} from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { IconDotsVertical, IconEdit, IconLogout2, IconTrash } from '@tabler/icons-react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useVirtualizer } from '@tanstack/react-virtual';
import DispatchChartsSegmentations, { ChartDispatchSegmentationsProps } from '../charts/dispatch';
import {
  CustomSegmentationData,
  PotentialSegmentationCriteria
} from '../../types';
import { SegmentationContext } from '../../wrapper';
import CriteriasBuilderSegmentation from '../../builder/criterias';
import InheritedCriterias from './inherited';
import { segmentationPossibleWorkflows } from '../../builder/workflows';
import { baseChartHeight, baseChartWidth } from '@/components/charts/utils';
import { useContainerRef } from '@/hooks/container';

interface RenderSegmentationCriteriasProps extends Omit<ChartDispatchSegmentationsProps, 'criteria' | 'totalCount'> {
  getCriterias: (outputEntitiesTmp?: SegmentationPossibleOutputEntityTypes) => PotentialSegmentationCriteria[],
  setCustomSegmentationData: (segmentationDataTmp: CustomSegmentationData) => void,
  updateSegmentation: () => void,
  outputEntities: SegmentationPossibleOutputEntityTypes,
  dataSetId: CustomSegmentationData['possibleDataSetIds'][0],
  segmentationData?: SegmentationData
}

function RenderSegmentationCriterias(props: RenderSegmentationCriteriasProps): ReactElement {
  const segmentationContext = useContext(SegmentationContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useContainerRef();
  const parentRef = useRef<HTMLDivElement>(null);
  const [columnCount, setColumnCount] = useState(1);

  const updateGridDimensions = () => {
    if (parentRef?.current) {
      const containerWidth = parentRef.current.offsetWidth;
      const calculatedColumnCount = Math.max(1, Math.floor(containerWidth / baseChartWidth));

      setColumnCount(calculatedColumnCount);
    }
  };

  const makeCriteria = (potentialCriterias: PotentialSegmentationCriteria[], segmentationData?: SegmentationData) =>
    potentialCriterias.map(potentialCriteria => ({
      ...potentialCriteria,
      ...(segmentationData?.segmentationCriterias?.[potentialCriteria.id] || {})
    }));
  const [potentialCriterias, setPotentialCriterias] = useState<PotentialSegmentationCriteria[]>([]);
  const [criterias, setCriterias] = useState(makeCriteria(potentialCriterias, props.segmentationData));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isCriteriaOrdering, setIsCriteriaOrdering] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (segmentationCriteria: ChartDispatchSegmentationsProps['criteria']) => props.setCustomSegmentationData({ ...(segmentationContext?.segmentation?.data as CustomSegmentationData), potentialSegmentationCriterias: (segmentationContext?.segmentation?.data.potentialSegmentationCriterias || potentialCriterias).filter(potentialCriteria => potentialCriteria.id !== segmentationCriteria.id) });
  const handleChangeDisplayType = (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: ChartDispatchSegmentationsProps['criteria']) => props.setCustomSegmentationData({ ...(segmentationContext?.segmentation?.data as CustomSegmentationData), potentialSegmentationCriterias: (segmentationContext?.segmentation?.data.potentialSegmentationCriterias || potentialCriterias).map(potentialCriteria => (potentialCriteria.id === segmentationCriteria.id ? { ...potentialCriteria, displayInfo: { ...potentialCriteria.displayInfo, displayType } } : potentialCriteria)) });

  useEffect(() => {
  }, [ref]);

  const rowCount = Math.ceil(criterias.length / columnCount);

  const virtualizer = useVirtualizer({
    count: rowCount,
    estimateSize: () => baseChartHeight + 55 + 8,
    getScrollElement: () => ref?.current || null
  });

  useEffect(() => {
    updateGridDimensions();
    window.addEventListener('resize', updateGridDimensions);
    setCriterias(makeCriteria(potentialCriterias, props.segmentationData));
    virtualizer._willUpdate();

    return () => window.removeEventListener('resize', updateGridDimensions);
  }, [potentialCriterias, props.segmentationData, ref?.current]);

  useEffect(() => {
    setPotentialCriterias(
      props.getCriterias()
        .filter(criteria => !props.filters.find(filter => filter.filterId === criteria.filterId && Object.values(segmentationPossibleWorkflows
          .find(possibleWorkflow => possibleWorkflow.dataSetId === props.dataSetId)?.entryPoints
          .find(entryPoint => entryPoint.entryPoint === props.outputEntities)?.correlationTable || {}).includes(filter.on)))
    );
  }, [props.outputEntities, props.filters, segmentationContext, props.segmentationData]);

  return (
    <Stack>
      {isCriteriaOrdering &&
        <Stack direction='row' spacing={4} alignItems='center' pl={6} pr={6}>
          <Button
            variant='outlined'
            onClick={() => {
              setIsCriteriaOrdering(false);
              props.updateSegmentation();
            }}
            startIcon={<IconLogout2 size='1.6rem' />}
            fullWidth
          >
            {t('segmentation.render.segmentationCriterias.closeEditMode')}
          </Button>
        </Stack>
      }
      {props.outputEntities && segmentationContext?.segmentation?.data &&
        <Stack direction='row' spacing={2} alignItems='center' width='100%' p={2} pt={0} pb={0} pr={4}>
          <CriteriasBuilderSegmentation
            customSegmentationData={segmentationContext.segmentation.data}
            setCustomSegmentationData={props.setCustomSegmentationData}
            outputEntities={props.outputEntities}
            onlyAddBtn={!isCriteriaOrdering}
            noAccordion
          />
          {!isCriteriaOrdering &&
            <IconButton
              size='small'
              id='criteria-menu-button'
              onClick={handleClick}
              aria-controls={open ? 'criteria-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <IconDotsVertical size='1.2rem'/>
            </IconButton>
          }
          <Menu
            id='criteria-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'criteria-menu-button'
            }}
          >
            <MenuItem onClick={() => {
              handleClose();
              setIsCriteriaOrdering(true);
            }}>
              <Stack direction='row' spacing={2} alignItems='center' p={2} pl={0}>
                <IconEdit size='1.2rem'/>
                <Typography>{t('segmentation.render.segmentationCriterias.editMode')}</Typography>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => {
              handleClose();
              if (segmentationContext?.segmentation) {
                props.setCustomSegmentationData({
                  ...segmentationContext.segmentation.data,
                  potentialSegmentationCriterias: []
                });
              }
            }}>
              <Stack direction='row' spacing={2} alignItems='center' p={2} pl={0} color={theme.palette.error.main}>
                <IconTrash size='1.2rem'/>
                <Typography>{t('segmentation.render.segmentationCriterias.removeAllCriterias')}</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </Stack>
      }
      {!isCriteriaOrdering && (criterias.length > 0 ?
        <Stack
          ref={parentRef}
          sx={{
            width: '100%',
            height: `${virtualizer.getTotalSize() + 8}px`,
            position: 'relative'
          }}
          p={2}
        >
          {virtualizer.getVirtualItems().map(item => (
            <Grid
              key={item.index}
              p={2}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${item.size}px`,
                transform: `translateY(${item.start}px)`
              }}
              container
              spacing={2}
            >
              {Array.from(Array(columnCount)).map((_, i) => (
                item.index * columnCount + i < criterias.length ?
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={i} size='grow' pb={2}>
                    <DispatchChartsSegmentations
                      {...props}
                      criteria={criterias[item.index * columnCount + i]}
                      totalCount={props.segmentationData?.totalCount || 0}
                      handleDelete={handleDelete}
                      handleChangeDisplayType={handleChangeDisplayType}
                    />
                  </Grid> :
                  null
              ))}
            </Grid>
          ))}
        </Stack> :
        <Grid container p={3} spacing={2} alignItems='center' justifyContent='center'>
          <Grid size={12}>
            <Typography textAlign='center'>{t('common.utils.or')}</Typography>
          </Grid>
          <Grid>
            <InheritedCriterias
              setCriterias={newCriterias => (segmentationContext?.segmentation ?
                props.setCustomSegmentationData({
                  ...(segmentationContext.segmentation.data),
                  potentialSegmentationCriterias: newCriterias
                }) :
                undefined)
              }
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}

export default RenderSegmentationCriterias;
