import React from 'react';
import { IconArrowGuide } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../../config/dna.config.json';
import SegmentationWrapper from '../../../../containers/segmentations/wrapper';
import RenderSegmentations from '../../../../containers/segmentations/render';
import { CustomRouteObject } from '@/router/types';

function routesAssistedSegmentations(id: string): CustomRouteObject {
  return createElementRoutes({
    id: `${id}-${dnaConfig.childRoutes.segmentations.assisted.label}`,
    path: `${dnaConfig.childRoutes.segmentations.baseUrl}/${dnaConfig.childRoutes.segmentations.assisted.baseUrl}`,
    slug: 'access?/:segmentationId',
    slugSuffix: true,
    component: <SegmentationWrapper>
      <RenderSegmentations />
    </SegmentationWrapper>,
    icon: IconArrowGuide
  });
}

export default routesAssistedSegmentations;
