import React, { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { IconTrash } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { CurrencyFormatterProps } from '@/utils';
import EvenOddTable from '@/components/tables/evenodd/table';
import { ListProps } from '../../lists/types';
import {
  BaseCustomUserData,
  CustomSegmentationData,
  CustomUserData,
  CustomUserDataAccess
} from '../types';
import { Column } from '@/components/tables/types';
import dnaConfig from '../../../../config/dna.config.json';
import {
  GroupDataCustomSegmentationService,
  UserDataCustomSegmentationService
} from '../services';
import CustomDialog from '@/components/dialog';

const getDataService = (accessTmp: CustomUserDataAccess, userId: string) => (accessTmp === 'me' ?
  new UserDataCustomSegmentationService<BaseCustomUserData<CustomSegmentationData>>({ userId }) :
  new GroupDataCustomSegmentationService<BaseCustomUserData<CustomSegmentationData>>({ groupId: accessTmp }));

function ListSegmentations(props: ListProps<CustomUserData<unknown>> & { updateList?: () => void }): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [segmentationDataDelete, setSegmentationDataDelete] = useState<CustomUserData<unknown> | undefined>();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name')
    },
    {
      id: 'segmentationCriterias',
      label: t('segmentation.render.segmentationCriterias.label')
    },
    {
      id: 'creationDate',
      label: t('common.dates.creationDate')
    },
    {
      id: 'lastUpdate',
      label: t('common.dates.lastUpdate')
    },
    {
      id: 'visibleFor',
      label: t('common.utils.visibleFor')
    },
    {
      id: 'actions',
      label: '',
      align: 'right'
    }
  ];

  const onDelete = () => {
    segmentationDataDelete?.groups?.[0] &&
      getDataService(segmentationDataDelete.groups?.[0] as CustomUserDataAccess, segmentationDataDelete.ownerId)?.delete?.(segmentationDataDelete._id)
        .then(() => {
          setOpenDialog(false);
          setSegmentationDataDelete(undefined);
          navigate(0);
        });
  };

  const rows = props.data.map(customUserData => ({
    id: customUserData._id,
    name: <Link href={`${dnaConfig.routes.admin.factory.segmentation.baseUrl}/${customUserData.groups?.[0]}/${customUserData._id as string}`}>{customUserData.title}</Link>,
    segmentationCriterias: (customUserData.data as CustomSegmentationData)?.potentialSegmentationCriterias?.length,
    creationDate: customUserData.date ? new Date(customUserData.date).toLocaleDateString() : undefined,
    lastUpdate: customUserData.lastUpdate ? new Date(customUserData.lastUpdate).toLocaleDateString() : undefined,
    visibleFor: customUserData.groups?.[0] === 'me' ? startCase(customUserData.ownerId.split('.').join(' ')) : customUserData.groups?.map(group => t(`segmentation.builder.config.access.${group}`))?.join(', '),
    actions: <Stack direction='row' justifyContent='flex-end'>
      <IconButton>
        <IconTrash
          onClick={
            () => {
              setOpenDialog(true);
              setSegmentationDataDelete(customUserData);
            }
          }
        />
      </IconButton>
    </Stack>
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <CustomDialog
        open={openDialog}
        handleClose={()=>{
          setOpenDialog(false);
        }}
        title={t('common.utils.deletion')}
        content={`${t('common.utils.deletionConfirmationTitle')} ${segmentationDataDelete?.title}`}
        buttonConfirmationText={t('common.utils.delete')}
        buttonConfirmationAction={onDelete}
        buttonCancelText={t('common.utils.cancel')}
        desctructive
      />
      <EvenOddTable<CurrencyFormatterProps> label='CustomSegmentation' {...props} columns={columns} rows={rows} localSort />
    </Box>
  );
}

export default ListSegmentations;
