import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconArrowRight, IconBuildingFactory, IconCirclesRelation, IconDashboard, IconLayoutList, IconListDetails, IconUserScan } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import { darken, lighten, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
// import DataElementDashboardWidget from '../dataElementsDashboard';

type CategorieCardProps = {
  title: string,
  icon: JSX.Element,
  bgcolor: string,
  redirectLink: string,
  totalEntities? : {
    persons: number,
    companies: number,
    portfolios: number,
    folders: number
  },
  width?: string | undefined,
  height?: string | undefined
}

function SubCard({ title, icon, bgcolor, redirectLink }: CategorieCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isPersonsOrCompanies = redirectLink.includes('relations');

  return (
    <Paper
      elevation={0}
      variant='hoverElevation2'
      onClick={(event) => {
        if (isPersonsOrCompanies) {
          event.stopPropagation();
          navigate({ pathname: 'relations', search: `searchEntityType=${  redirectLink === 'network/persons' ? 'deecPerson' : 'deecCompany'}` });
        } else {
          navigate(redirectLink.toLowerCase());
        }
      }}
      sx={{
        width: '100%',
        border: 0.7,
        p: 2,
        bgcolor,
        overflowX: 'auto',
        borderRadius: 1,
        cursor: 'pointer'
      }}>
      <Grid container spacing={2} p={2} justifyContent='space-between'>
        <Grid minWidth='fit-content' minHeight='fit-content'>
          <Box
            width='fit-content'
            borderRadius={1}
            borderColor={darken(bgcolor, 0.3)}
            bgcolor={darken(bgcolor, 0.1)}
            p={2}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              aspectRatio: '1 / 1'
            }}>
            {icon}
          </Box>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
      </Grid>
      <Grid flexDirection='row' justifyContent='end'>
        <Button
          size='small'
          variant='outlinedIcon'
          sx={{
            alignSelf: 'flex-end',
            marginLeft: 'auto !important',
            border: 'solid 1px',
            '&:hover': {
              color: theme.palette.grey['800'],
              border: 'solid 1px transparent',
              bgcolor: darken(bgcolor, 0.1)
            }
          }}
          onClick={(event) => {
            if (isPersonsOrCompanies) {
              event.stopPropagation();
              navigate({ pathname: 'relations', search: `searchEntityType=${  redirectLink === 'network/persons' ? 'deecPerson' : 'deecCompany'}` });
            } else {
              navigate(redirectLink.toLowerCase());
            }
          }}
        >
          <IconArrowRight size='1.2rem' />
        </Button>
      </Grid>
    </Paper>
  );
}

function CategorieCard({ title, icon, bgcolor, redirectLink, totalEntities }: CategorieCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isPersonsOrCompanies = redirectLink === 'persons' || redirectLink === 'companies';

  return (
    <Paper
      elevation={0}
      variant='hoverElevation2'
      onClick={() => {
        navigate(redirectLink.toLowerCase());
      }}
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        border: 0.7,
        p: 2,
        bgcolor,
        overflowX: 'auto',
        borderRadius: 1,
        cursor: 'pointer'
      }}>
      <Grid container>
        <Grid minWidth='fit-content'>
          <Box
            borderRadius={1}
            borderColor={darken(bgcolor, 0.3)}
            bgcolor={darken(bgcolor, 0.1)}
            p={2}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              aspectRatio: '1 / 1'
            }}>
            {icon}
          </Box>
        </Grid>
        <Grid size='grow' />
        <Grid minWidth='fit-content'>
          {totalEntities &&
            <Typography
              p={1}
              variant='h5'
            >
              {totalEntities[redirectLink as keyof typeof totalEntities] || '0'}
            </Typography>
          }
        </Grid>
        <Grid size={12} minWidth='fit-content'>
          <Typography variant='h4'>{title}</Typography>
        </Grid>
        <Grid size={12} height={12} />
        {isPersonsOrCompanies ?
          <Grid
            size={6}
            onClick={(event: { stopPropagation: () => void }) => {
              event.stopPropagation();
              navigate({ pathname: 'relations', search: `searchEntityType=${ redirectLink === 'persons' ? 'deecPerson' : 'deecCompany'}` });
            }}
          >
            <SubCard
              title='Relations'
              icon={<IconCirclesRelation size='0.85rem'/>}
              bgcolor={lighten(bgcolor, 0.3)}
              redirectLink={`relations/${redirectLink}`}
            />
          </Grid> :
          <Grid size={6} />
        }
        <Grid size='grow' alignContent='end'>
          <Button
            size='large'
            variant='outlinedIcon'
            sx={{
              marginLeft: 'auto !important',
              border: 'solid 1px',
              '&:hover': {
                color: theme.palette.grey['800'],
                border: 'solid 1px transparent',
                bgcolor: darken(bgcolor, 0.1)
              }
            }}
          >
            <IconArrowRight size='1.4rem' />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function HomePageAdminComponent(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const sectionLeftWidth = 70;

  const adminCategorie = [
    {
      title: 'Admin',
      pt: 0,
      card: [
        {
          title: 'DataElements',
          icon: <IconListDetails size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: theme.palette.error.light,
          redirectTo: 'dataelements'
        },
        {
          title: t('entities.label'),
          icon: <IconUserScan size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: theme.palette.error.light,
          redirectTo: 'entities'
        },
        {
          title: 'DataBlocks',
          icon: <IconLayoutList size='1.6rem' color={theme.palette.grey['800']}/>,
          bgColor: theme.palette.error.light,
          redirectTo: 'datablocks'
        }
      ]
    }
  ];

  return (
    <Box width='100%' height='100%' overflow='auto'>
      <Grid container flexDirection='row' overflow='auto'>
        <Grid width={`${sectionLeftWidth}%`}>
          <Stack width='100%' pr={4}>
            <Stack bgcolor='' borderRadius={1} p={4} spacing={2}>
              {/* <Stack height='20%'>
              <Typography>
                DataElement Overview
              </Typography>
            </Stack> */}
              {/* <DataElementDashboardWidget /> */}
            </Stack>
            <Grid container width='100%' justifyContent='center' pb={4} pt={4}>
              <Grid
                p={2}
                pt={0}
                pb={2}
                minWidth='50%'
              >
                <CategorieCard
                  title='Factory'
                  icon={<IconBuildingFactory size='1.6rem' color={theme.palette.grey['800']}/>}
                  bgcolor={lighten(theme.palette.primary.main, 0.6)}
                  redirectLink='factory'
                  totalEntities={undefined}
                />
              </Grid>
              <Grid
                p={2}
                pt={0}
                pb={2}
                minWidth='50%'
              >
                <CategorieCard
                  title='Dashboard'
                  icon={<IconDashboard size='1.6rem' color={theme.palette.grey['800']}/>}
                  bgcolor={lighten(theme.palette.secondary.main, 0.6)}
                  redirectLink='dashboard'
                  totalEntities={undefined}
                />
              </Grid>
            </Grid>
            {adminCategorie.map(category =>
              <Stack borderRadius={1} p={4} pt={category.pt} spacing={2}>
                <Typography variant='h5'>
                  {category.title}
                </Typography>
                <Grid container height='85%' spacing={2}>
                  {category.card.map(card =>
                    <Grid minWidth={123} p={2}>
                      <SubCard title={card.title} icon={card.icon} bgcolor={card.bgColor} redirectLink={card.redirectTo}/>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            )}

          </Stack>
        </Grid>

        <Grid container height='80vh' width='30%' bgcolor='#E9ECEF' borderRadius={2} />

      </Grid>
    </Box>
  );
}

export default HomePageAdminComponent;
