import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ApiResponse, Nullable } from '@deecision/deecision-interfaces';
import { SegmentationRequest } from '@deecision/dna-interfaces';
import {
  BaseCustomUserData,
  CustomSegmentationData,
  CustomUserData,
  CustomUserDataAccess
} from './types';
import {
  possibleCriteriaOnTypes,
  possibleCriteriaTypes,
  possibleDataSetIds,
  possibleFilterTypes,
  possibleOutputEntities,
  possibleWorkflowIds
} from './builder/workflows';
import { getSegmentationService } from './getters/segmentation.getters.segmentations';

const defaultNewCustomSegmentationTitle = 'My Segmentation';
interface SegmentationContextType {
  segmentation: BaseCustomUserData<CustomSegmentationData> | undefined,
  setSegmentation: Dispatch<SetStateAction<BaseCustomUserData<CustomSegmentationData> | undefined>>,
  save: (accessTmp: CustomUserDataAccess, setNewAccess?: true, asNew?: true) => void,
  deleteSegmentation: () => void,
  updateAccess: (accessTmp: CustomUserDataAccess) => void
}

export const SegmentationContext = createContext<Nullable<SegmentationContextType>>(null);
export const SegmentationRequestContext = createContext<Nullable<{ segmentationRequest: Nullable<SegmentationRequest>, setSegmentationRequest: Dispatch<SetStateAction<Nullable<SegmentationRequest>>> }>>(null);

function SegmentationWrapper(props: { access?: CustomUserDataAccess, children: React.ReactNode }): ReactElement {
  const { segmentationId, id, access } = useParams();
  const { pathname, search } = useLocation();
  const [data, setData] = useState<ApiResponse<BaseCustomUserData<CustomSegmentationData>>>();
  const navigate = useNavigate();
  const keycloak = useKeycloak();
  const [customSegmentation, setCustomSegmentation] = useState<BaseCustomUserData<CustomSegmentationData>>();
  const [segmentationRequest, setSegmentationRequest] = useState<Nullable<SegmentationRequest>>(null);

  const save = useCallback((accessTmp: CustomUserDataAccess, setNewAccess?: true, asNew?: true) => {
    const dataService = getSegmentationService<BaseCustomUserData<CustomSegmentationData>>(accessTmp, keycloak.keycloak.tokenParsed?.preferred_username);

    if (customSegmentation) {
      if (asNew) {
        delete customSegmentation._id;
        delete customSegmentation.date;
        delete customSegmentation.lastUpdate;

        customSegmentation.ownerId = keycloak.keycloak.tokenParsed?.preferred_username;
        customSegmentation.date = new Date();
        customSegmentation.lastUpdate = new Date();

        getSegmentationService<BaseCustomUserData<CustomSegmentationData>>('me', keycloak.keycloak.tokenParsed?.preferred_username).post?.({
          ...customSegmentation,
          groups: ['me']
        })
          .then((res) => {
            setData(res);
            navigate({
              pathname: pathname
                .replace(segmentationId || id || 'create', (res.data as CustomUserData)._id)
                .replace(`assisted/${access}`, 'custom'),
              search
            });
          });
      } else {
        data?.data?._id && !setNewAccess
          ? dataService.put?.(data.data._id, {
            ...customSegmentation,
            groups: [accessTmp],
            lastUpdate: new Date()
          })
            .then((res) => {
              setData(res);
            })
          : dataService.post?.({
            ...customSegmentation,
            groups: [accessTmp]
          })
            .then((res) => {
              setData(res);
              navigate({
                pathname: pathname
                  .replace(segmentationId || id || 'create', (res.data as CustomUserData)._id)
                  .replace(accessTmp !== 'me' ? `assisted/${access}` : '', accessTmp !== 'me' ? `assisted/${accessTmp}` : '')
                  .replace(pathname.includes('factory') ? `/${access}/` : '', pathname.includes('factory') ? `/${accessTmp}/` : '')
                  .replace(accessTmp === 'me' ? `assisted/${access}` : 'custom', accessTmp === 'me' ? 'custom' : `assisted/${accessTmp}`),
                search
              });
            });
      }
    }
  }, [keycloak.keycloak.tokenParsed?.preferred_username, customSegmentation]);

  const updateAccess = (accessTmp: CustomUserDataAccess) => {
    const dataService = getSegmentationService<BaseCustomUserData<CustomSegmentationData>>(data?.data?.groups?.[0] || 'me', keycloak.keycloak.tokenParsed?.preferred_username);

    if (customSegmentation && data?.data?._id) {
      dataService.delete?.(data.data._id)
        .then(() => {
          save(accessTmp, true);
        });
    } else {
      setCustomSegmentation(prevState => (prevState ? { ...prevState, groups: [accessTmp] } : undefined));
    }
  };

  const deleteSegmentation = () => {
    if (data?.data?._id) {
      getSegmentationService<BaseCustomUserData<CustomSegmentationData>>(data.data.groups?.[0] || 'me', keycloak.keycloak.tokenParsed?.preferred_username).delete?.(data.data._id)
        .then(() => navigate({ pathname: pathname.replace(segmentationId || id || '', 'create').replace(`assisted/${access}`, 'custom'), search }));
    }
  };

  useEffect(() => {
    if ((segmentationId || id) && !data && !pathname.includes('create') && !pathname.includes('venn')) {
      if (props.access) {
        getSegmentationService<BaseCustomUserData<CustomSegmentationData>>(props.access, keycloak.keycloak.tokenParsed?.preferred_username).get(segmentationId || id || '')
          .then(res => setData(res))
          .catch(() => navigate({ pathname: pathname.replace(segmentationId || id || '', 'create').replace(`assisted/${access}`, 'custom'), search }));
      } else if (access) {
        getSegmentationService<BaseCustomUserData<CustomSegmentationData>>(access as CustomUserDataAccess, keycloak.keycloak.tokenParsed?.preferred_username).get(segmentationId || id || '')
          .then(res => setData(res))
          .catch(() => navigate({
            pathname: pathname.replace(segmentationId || id || '', 'create').replace(`assisted/${access}`, 'custom'),
            search
          }));
      } else {
        getSegmentationService<BaseCustomUserData<CustomSegmentationData>>('me', keycloak.keycloak.tokenParsed?.preferred_username).get(segmentationId || id || '')
          .then(res => setData(res))
          .catch(() => getSegmentationService<BaseCustomUserData<CustomSegmentationData>>('admins', keycloak.keycloak.tokenParsed?.preferred_username).get(segmentationId || id || '')
            .then(res => setData(res))
            .catch(() => getSegmentationService<BaseCustomUserData<CustomSegmentationData>>('everyone', keycloak.keycloak.tokenParsed?.preferred_username).get(segmentationId || id || '')
              .then(res => setData(res))
              .catch(() => navigate({
                pathname: pathname.replace(segmentationId || id || '', 'create').replace(`assisted/${access}`, 'custom'),
                search
              }))));
      }
    } else {
      setCustomSegmentation(((segmentationId || id) && !pathname.includes('create') && !pathname.includes('venn')) ? data?.data : {
        title: defaultNewCustomSegmentationTitle,
        type: 'segmentation',
        groups: ['me'],
        ownerId: keycloak.keycloak.tokenParsed?.preferred_username,
        date: new Date(),
        lastUpdate: new Date(),
        data: {
          possibleDataSetIds: possibleDataSetIds(),
          possibleOutputEntities: possibleOutputEntities(),
          possibleWorkflowIds: possibleWorkflowIds(),
          possibleFilterTypes: possibleFilterTypes(),
          possibleCriteriaTypes: possibleCriteriaTypes(),
          possibleCriteriaOnTypes: possibleCriteriaOnTypes(),
          preFilters: [],
          potentialSegmentationCriterias: []
        } as CustomSegmentationData
      });
    }
  }, [data, segmentationId || id]);

  useEffect(() => {
    if (keycloak.keycloak.tokenParsed?.preferred_username && customSegmentation) {
      setCustomSegmentation({
        ...customSegmentation,
        ownerId: keycloak.keycloak.tokenParsed?.preferred_username
      });
    }
  }, [keycloak.keycloak.tokenParsed?.preferred_username]);

  const segmentationContextValue = useMemo(() => ({ segmentation: customSegmentation, setSegmentation: setCustomSegmentation, save, deleteSegmentation, updateAccess }), [customSegmentation]);

  const segmentationRequestContextValue = useMemo(() => ({ segmentationRequest, setSegmentationRequest }), [segmentationRequest]);

  return (
    <SegmentationContext.Provider value={segmentationContextValue}>
      <SegmentationRequestContext.Provider value={segmentationRequestContextValue}>
        {props.children}
      </SegmentationRequestContext.Provider>
    </SegmentationContext.Provider>
  );
}

export default SegmentationWrapper;
