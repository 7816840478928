import React from 'react';
import { IconFilterEdit } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../../../config/dna.config.json';
import SegmentationWrapper from '../../../../containers/segmentations/wrapper';
import RenderSegmentations from '../../../../containers/segmentations/render';
import { CustomRouteObject } from '@/router/types';

function routesCustomSegmentations(id: string): CustomRouteObject {
  return createElementRoutes({
    id: `${id}-${dnaConfig.childRoutes.segmentations.custom.label}`,
    path: `${dnaConfig.childRoutes.segmentations.baseUrl}/${dnaConfig.childRoutes.segmentations.custom.baseUrl}`,
    slug: 'segmentationId',
    slugSuffix: true,
    component: <SegmentationWrapper access='me'>
      <RenderSegmentations />
    </SegmentationWrapper>,
    icon: IconFilterEdit
  });
}

export default routesCustomSegmentations;
