import React from 'react';
import { IconChartCircles } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import { VennDiagramConfig } from '@/main/containers/vennDiagram/types';
import dnaConfig from '../../../../config/dna.config.json';
import RenderVennDiagram from '../../../containers/vennDiagram/render';
import SegmentationWrapper from '../../../containers/segmentations/wrapper';
import { CustomRouteObject } from '@/router/types';
import VennDiagramWrapper from '@/main/containers/vennDiagram/wrapper';

function routesVennDiagram(id: string): CustomRouteObject {
  return createElementRoutes<VennDiagramConfig[]>({
    id: `${id}-${dnaConfig.childRoutes.vennDiagrams.diagram.baseUrl}`,
    path: `${dnaConfig.childRoutes.vennDiagrams.baseUrl}/${dnaConfig.childRoutes.vennDiagrams.diagram.baseUrl}`,
    slug: 'vennDiagramId',
    slugSuffix: true,
    component:
    <VennDiagramWrapper>
      <SegmentationWrapper>
        <RenderVennDiagram />
      </SegmentationWrapper>
    </VennDiagramWrapper>,
    // Service: new VennDiagramServices(),
    icon: IconChartCircles
  });
}

export default routesVennDiagram;
