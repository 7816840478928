import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { IconTrash } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import { CurrencyFormatterProps } from '@/utils';
import dnaConfig from '../../../../config/dna.config.json';
import { Column, Row } from '@/components/tables/types';
import EvenOddTable from '@/components/tables/evenodd/table';
import { VennDiagramConfig } from '../types';
import VennDiagramServices from '../services';
import CustomDialog from '../../../../components/dialog';

type ListVennDiagramType = {
  totalCount: number,
  actions?: ReactElement[] | undefined,
  actionsPadding: string | undefined,
  service: VennDiagramServices<unknown>,
  data: VennDiagramConfig[]
}

function ListVennDiagram(props: ListVennDiagramType): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [diagramDataDelete, setDiagramDataDelete] = useState<VennDiagramConfig | undefined>();

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sortPath: 'props.children'
    },
    {
      id: 'segments',
      label: 'Segments'
    },
    {
      id: 'objectType',
      label: t('entities.label')
    },
    {
      id: 'creationDate',
      label: t('common.dates.creationDate')
    },
    {
      id: 'lastUpdate',
      label: t('common.dates.lastUpdate')
    },
    {
      id: 'visibleFor',
      label: t('common.utils.visibleFor')
    },
    {
      id: 'actions',
      label: '',
      align: 'right'
    }
  ];

  const [rows, setRows] = useState<Row<CurrencyFormatterProps>[]>([]);

  const onDelete = () => {
    diagramDataDelete?._id &&
      props.service?.delete?.(diagramDataDelete._id)
        .then(() => {
          setOpenDialog(false);
          setDiagramDataDelete(undefined);
          navigate(0);
        });
  };

  useEffect(() => {
    setRows(props.data.map(vennDiagramData => ({
      id: vennDiagramData._id || '',
      name: <Link href={`${dnaConfig.routes.admin.factory.vennDiagram.baseUrl}/${vennDiagramData._id as string}`}>{vennDiagramData.label}</Link>,
      segments: vennDiagramData.segments?.length || 0,
      objectType: t(`segmentation.vennDiagram.on.${vennDiagramData.objectType}`),
      creationDate: vennDiagramData.date ? new Date(vennDiagramData.date).toLocaleDateString() : undefined,
      lastUpdate: vennDiagramData.lastUpdate ? new Date(vennDiagramData.lastUpdate).toLocaleDateString() : undefined,
      visibleFor: vennDiagramData.ownerType === 'me' ? startCase(vennDiagramData.ownerId.split('.').join(' ')) : t(`segmentation.builder.config.access.${vennDiagramData.ownerType}`),
      actions: <Stack direction='row' justifyContent='flex-end'>
        <IconButton>
          <IconTrash
            onClick={
              () => {
                setOpenDialog(true);
                setDiagramDataDelete(vennDiagramData);
              }
            }
          />
        </IconButton>
      </Stack>
    })));
  }, [props.data]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <CustomDialog
        open={openDialog}
        handleClose={()=>{
          setOpenDialog(false);
        }}
        title={t('common.utils.deletion')}
        content={`${t('common.utils.deletionConfirmationTitle')} ${diagramDataDelete?.label}`}
        buttonConfirmationText={t('common.utils.delete')}
        buttonConfirmationAction={onDelete}
        buttonCancelText={t('common.utils.cancel')}
        desctructive
      />
      <EvenOddTable<CurrencyFormatterProps> label='VennDiagram' {...props} columns={columns} rows={rows} />
    </Box>
  );
}

export default ListVennDiagram;
