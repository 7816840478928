import React from 'react';
import { IconChartInfographic, IconPlus } from '@tabler/icons-react';
import { Navigate } from 'react-router-dom';
import createElementRoutes from '@/router/create';
import VennDiagramBuilder from '../../../../containers/vennDiagram/builder';
import dnaConfig from '@/config/dna.config.json';
import VennDiagramWrapper from '@/main/containers/vennDiagram/wrapper';

const routesVennDiagramFactory = createElementRoutes({
  id: dnaConfig.routes.admin.factory.vennDiagram.label,
  path: dnaConfig.routes.admin.factory.vennDiagram.baseUrl,
  icon: IconChartInfographic,
  component: <Navigate to={dnaConfig.routes.admin.factory.vennDiagram.create.baseUrl} />,
  idComponent:
    <VennDiagramWrapper>
      <VennDiagramBuilder />
    </VennDiagramWrapper>,
  childrens: [
    createElementRoutes({
      id: dnaConfig.routes.admin.factory.vennDiagram.create.label,
      path: dnaConfig.routes.admin.factory.vennDiagram.create.baseUrl,
      icon: IconPlus,
      component:
        <VennDiagramWrapper>
          <VennDiagramBuilder />
        </VennDiagramWrapper>
    })
  ]
});

export default routesVennDiagramFactory;
