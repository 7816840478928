import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { IconBuildings, IconDeviceFloppy, IconUsers } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';
import ConfigVennDiagram from './config';
import SegmentsVennDiagram from './segments';
import { VennDiagramContext } from '@/main/containers/vennDiagram/wrapper';

const defaultNewCustomVennDiagramTitle = 'My Venn Diagram';

function VennDiagramBuilder(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const vennDiagramContext = useContext(VennDiagramContext);
  const { pathname } = useLocation();

  return (
    vennDiagramContext?.vennDiagram ?
      <div key={vennDiagramContext.vennDiagram?._id}>
        <Stack
          spacing={4}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            p: 2,
            overflow: 'auto',
            width: '100%',
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 1,
            bgcolor: theme.palette.grey[50]
          }}
        >
          <Stack spacing={4} direction='row' alignItems='center' justifyContent='space-between'>
            <Stack spacing={4} direction='row' alignItems='center'>
              <Typography variant='h2' sx={{ minWidth: 'max-content' }}>
                {vennDiagramContext.vennDiagram?.label || defaultNewCustomVennDiagramTitle}
              </Typography>
              <Chip
                label={t(`segmentation.builder.config.access.${vennDiagramContext.vennDiagram?.ownerType || 'me'}`)}
                color='primary'
                size='small'
              />
              <Chip
                label={
                  <Stack direction='row' alignItems='center' spacing={2}>
                    {vennDiagramContext.vennDiagram.objectType === 'deecPerson' ? <IconUsers size='1rem' /> : <IconBuildings size='1rem' />}
                    <Typography variant='body2'>{t(`segmentation.vennDiagram.on.${vennDiagramContext.vennDiagram.objectType}`)}</Typography>
                  </Stack>
                }
                color={vennDiagramContext.vennDiagram.objectType === 'deecPerson' ? 'primary' : 'secondary'}
                size='small'
                sx={{
                  backgroundColor: vennDiagramContext.vennDiagram.objectType === 'deecPerson' ? 'primary.light' : 'secondary.light',
                  color: vennDiagramContext.vennDiagram.objectType === 'deecPerson' ? 'primary.dark' : 'secondary.dark'
                }}
              />
            </Stack>
          </Stack>
          <Button
            variant='contained'
            startIcon={<IconDeviceFloppy size='1.6rem' />}
            onClick={() => vennDiagramContext.save(vennDiagramContext?.vennDiagram?.ownerType || 'me', pathname.includes('create'))}
            disabled={vennDiagramContext.vennDiagram.segments.length === 0}
          >
            {t('common.utils.save')}
          </Button>
        </Stack>
        <Stack alignItems='center' pt={4}>
          <ConfigVennDiagram
            key={t('segmentation.builder.steps.initialize.label')}
            data={vennDiagramContext.vennDiagram}
            setData={vennDiagramContext.setVennDiagram}
            access={vennDiagramContext.vennDiagram?.ownerType || 'me'}
            setAccess={vennDiagramContext.updateAccess}
          />
          <SegmentsVennDiagram
            key='Segment'
            data={vennDiagramContext.vennDiagram}
            setData={vennDiagramContext.setVennDiagram}
          />
        </Stack>
      </div> :
      <></>
  );
}

export default VennDiagramBuilder;
