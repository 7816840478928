import React, { ReactElement, useEffect, useState } from 'react';
import { AxiosInstance } from 'axios';
import { Alert, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ServiceError from '@/api/services/error';

export interface ErrorWrapperProps {
  axiosInstance: AxiosInstance
}

function ErrorWrapper(props: ErrorWrapperProps & { children: ReactElement }): ReactElement {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const closeZodAlertSnackbar = () => {
    const alertSnackbar = document.getElementById('zodAlertSnackbar');

    if (alertSnackbar) {
      alertSnackbar.style.display = 'none';
    }
  };

  useEffect(() => {
    props.axiosInstance.interceptors.response.use(res => res, (error) => {
      const errorMsgTmp = ServiceError.getErrorMsg(error);

      if (!errorMsgTmp.includes('canceled')) {
        setErrorMsg(errorMsgTmp);
        setSnackbarOpen(true);
      }

      return Promise.reject(error);
    });
  }, []);

  return (
    <>
      {props.children}
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} variant='filled' sx={{ with: '100%' }} severity='error'>
          <Typography variant='h5'>{errorMsg}</Typography>
        </Alert>
      </Snackbar>
      <Snackbar open id='zodAlertSnackbar' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ display: 'none' }}>
        <Alert variant='filled' severity='warning' onClose={closeZodAlertSnackbar} sx={{ backgroundColor: 'warning.light', borderColor: 'warning.dark', color: 'warning.dark' }}>
          <Typography variant='h5'>
            {t('common.utils.zodError')}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default ErrorWrapper;
