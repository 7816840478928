import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  IconArrowRight,
  IconBuildings,
  IconChartCircles, IconPlus,
  IconRefresh,
  IconTimelineEventPlus,
  IconTrash,
  IconUsers
} from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Chip, { ChipOwnProps } from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { VennDiagramConfig } from '@/main/containers/vennDiagram/types';
import { getEntityTypeColor, getEntityTypeI18nKey } from '@/main/providers/getter';
import LoadingList from '@/components/loading/list';
import CustomAccordion from '@/components/accordion';
import VennDiagramServices from '@/main/containers/vennDiagram/services';
import DeleteModalsRenderSegmentations
  from '@/main/containers/segmentations/render/modals/delete.modals.render.segmentations';

function VennDiagram(props: { vennDiagram: VennDiagramConfig, userId?: string, disabled?: boolean, updateList: () => void }): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const vennDataFactoryService = new VennDiagramServices<VennDiagramConfig>();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };
  const handleCloseOpenConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const redirect = () => navigate({ // Change redirect route here
    pathname: `diagram/${props.vennDiagram._id}`,
    search: location.search
  });

  return (
    <Paper
      key={props.vennDiagram._id}
      variant={!props.disabled ? 'hoverElevation2' : undefined}
      sx={{
        width: '100%',
        p: 2,
        cursor: !props.disabled ? 'pointer' : undefined,
        opacity: props.disabled ? 0.5 : 1
      }}
      onClick={!props.disabled ? redirect : undefined}
    >
      <Stack display='flex' direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
        <Stack flexWrap='wrap' rowGap={2} direction='row' spacing={2} alignItems='center'>
          <IconChartCircles size='1.6rem' />
          <Typography variant='h4' pr={3}>{props.vennDiagram.label}</Typography>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                {props.vennDiagram.objectType === 'deecPerson' ? <IconUsers size='1rem' /> : <IconBuildings size='1rem' />}
                <Typography variant='body2'>
                  {t(getEntityTypeI18nKey(props.vennDiagram.objectType))}
                </Typography>
              </Stack>
            }
            size='small'
            color={getEntityTypeColor(props.vennDiagram.objectType) !== 'secondary' ? getEntityTypeColor(props.vennDiagram.objectType) as ChipOwnProps['color'] : undefined}
            sx={{
              bgcolor: getEntityTypeColor(props.vennDiagram.objectType) === 'secondary' ? theme.palette.secondary.light : undefined,
              color: getEntityTypeColor(props.vennDiagram.objectType) === 'secondary' ? theme.palette.secondary.dark : undefined
            }}
          />
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconChartCircles size='1rem' />
                <Typography variant='body2'>
                  {`${props.vennDiagram.segments.length} ${t('segmentation.vennDiagram.render.segments.label')}`}
                </Typography>
              </Stack>
            }
            color='primary'
            size='small'
          />
          <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconTimelineEventPlus size='1rem' />
                  <Typography variant='body2'>
                    {props.vennDiagram?.date
                      ? `${new Date(props.vennDiagram.date).toLocaleDateString()} ${new Date(props.vennDiagram.date).toLocaleTimeString()}`
                      : ''}
                  </Typography>
                </Stack>
              }
              color='success'
              size='small'
            />
          </Tooltip>
          <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconRefresh size='1rem' />
                  <Typography variant='body2'>
                    {props.vennDiagram?.lastUpdate
                      ? `${new Date(props.vennDiagram.lastUpdate).toLocaleDateString()} ${new Date(props.vennDiagram.lastUpdate).toLocaleTimeString()}`
                      : ''}
                  </Typography>
                </Stack>
              }
              sx={{
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }}
              size='small'
            />
          </Tooltip>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          {!props.disabled &&
          <Button
            variant='containedIcon'
            onClick={(event) => {
              event.stopPropagation();
              redirect();
            }}
            sx={{ minWidth: 'max-content' }}
          >
            <IconArrowRight size='1.4rem' />
          </Button>
          }
          {props.userId && props.vennDiagram._id && (
            <IconButton>
              <IconTrash
                size='1.6rem'
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenConfirmDelete();
                }}
                color={theme.palette.error.main}
              />
            </IconButton>
          )}
          <DeleteModalsRenderSegmentations
            openConfirmDelete={openConfirmDelete}
            handleCloseOpenConfirmDelete={handleCloseOpenConfirmDelete}
            handleDeleteSegmentation={() =>
              vennDataFactoryService
                .delete?.(props.vennDiagram._id || '')
                .then(() =>
                  (props.updateList ? props.updateList() : navigate(0))
                )
            }
            title={t('segmentation.vennDiagram.render.settings.confirmDelete')}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

function SelectComponentVennDiagram(): ReactElement {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const keycloak = useKeycloak();
  const [assistedVennDiagrams, setAssistedVennDiagrams] = useState<VennDiagramConfig[]>();
  const [personalVennDiagrams, setPersonalVennDiagrams] = useState<VennDiagramConfig[]>();
  const [loadingAssisted, setLoadingAssisted] = useState(false);
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const entityType = searchParams.get('entityType');
  const vennDataFactoryService = new VennDiagramServices<VennDiagramConfig>();

  const getVennList =  useCallback(() => {
    if (keycloak.keycloak.tokenParsed) {
      setLoadingAssisted(true);
      setLoadingPersonal(true);
      vennDataFactoryService.getAll({}).then((res) => {
        setPersonalVennDiagrams(res.data?.filter(seg => seg.ownerType === 'me' && seg.ownerId === keycloak.keycloak.tokenParsed?.preferred_username));
        setAssistedVennDiagrams(res.data?.filter(seg => seg.ownerType !== 'me'));
        setLoadingAssisted(false);
        setLoadingPersonal(false);
      });
    }
  }, [keycloak.keycloak]);

  useEffect(() => {
    getVennList();
  }, [pathname]);

  return (
    <Stack minWidth={600} spacing={4}>
      <Button variant='contained' startIcon={<IconPlus size='1.6rem' />} sx={{ width: 'fit-content', alignSelf: 'flex-end' }} onClick={() => navigate({ pathname: 'diagram/create', search })}>
        {t('segmentation.vennDiagram.create')}
      </Button>
      <Stack>
        <CustomAccordion title={t('segmentation.vennDiagram.assisted.multiFullLabel')} defaultOpen disabled={!loadingAssisted && (assistedVennDiagrams?.length || 0) < 1}>
          <Stack p={4} spacing={2}>
            {assistedVennDiagrams ?
              assistedVennDiagrams.map(vennDiagram => (
                <VennDiagram vennDiagram={vennDiagram} disabled={!entityType ? false : !(vennDiagram.objectType?.includes('Person') && entityType?.includes('Person') || (vennDiagram.objectType?.includes('Compan') && entityType?.includes('Compan')))} updateList={getVennList}/>
              )) :
              loadingAssisted ? <LoadingList rows={3} rowHeight={58} /> : null
            }
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={t('segmentation.vennDiagram.augmented.multiCustomLabel')} defaultOpen disabled={!loadingPersonal && (personalVennDiagrams?.length || 0) < 1}>
          <Stack p={4} spacing={2}>
            {personalVennDiagrams ?
              personalVennDiagrams.map(vennDiagram => (
                <VennDiagram vennDiagram={vennDiagram} userId={keycloak.keycloak.tokenParsed?.preferred_username} disabled={!entityType ? false : !(vennDiagram.objectType?.includes('Person') && entityType?.includes('Person') || (vennDiagram.objectType?.includes('Compan') && entityType?.includes('Compan')))} updateList={getVennList}/>
              )) :
              loadingPersonal ? <LoadingList rows={3} rowHeight={58} /> : null
            }
          </Stack>
        </CustomAccordion>
      </Stack>

    </Stack>
  );
}

export default SelectComponentVennDiagram;
